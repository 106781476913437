import React from "react";
import HistoryIcon from "../assets/images/History_B.svg";
import { useNavigate } from "react-router-dom";
import PaymentIcon from "../assets/images/Payment Method1 1.svg";
import BuyCreditIcon from "../assets/images/By Credits 1.svg";
import SubscriptionIcon from "../assets/images/My Subscription 1 (1).svg";

export default function BillingSidebar(props: any) {
  const navigate = useNavigate();

  const onMySubscriptionClick = () => {
    navigate("/mysubscription");
  };
  const onPaymentHistoryClick = () => {
    navigate("/paymenthistory");
  };
  const onBuyCreditsClick= () => {
    navigate("/buycredits");
  };
  const onPaymentMethodClick= () => {
    navigate("/paymentmethod");
  };
  return (
    <div className="pt-4 pf-sidebar-block p-0">
      <>
        <span
          className={props.pagename == "billing-payment-method" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
          onClick={onPaymentMethodClick}
        >
          <img src={PaymentIcon} className={props.pagename == "billing-payment-method" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} />
          <span className="sidebar-menu-title">Payment Method</span>{" "}
        </span>

        <span
          className={props.pagename == "buy-credits" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
          onClick={onBuyCreditsClick}
        >
          <img src={BuyCreditIcon} className={props.pagename == "buy-credits" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} />
          <span className="sidebar-menu-title">Buy Credits</span>{" "}
        </span>
        <span
          className={props.pagename == "billing-payment-history" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
          onClick={onPaymentHistoryClick}
        >
          <img
            src={HistoryIcon}
            className={props.pagename == "billing-payment-history" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "}
          />
          <span className="sidebar-menu-title">History</span>{" "}
        </span>
        <span
          className={props.pagename == "mysubsciption" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
          onClick={onMySubscriptionClick}
        >
          <img src={SubscriptionIcon} className={props.pagename == "mysubsciption" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} />
          <span className="sidebar-menu-title">My Subscription</span>{" "}
        </span>
      </>
    </div>
  );
}
