import { useEffect, useState, useRef } from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import { OverlayPanel } from "primereact/overlaypanel";
import Skip from "./Skip";
import { useParams, useLocation } from "react-router-dom";

export default function SetupStart() {
  const navigate = useNavigate();
  const skipBtnRef = useRef<OverlayPanel>(null);
  const toast: any = useRef("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const hostname = window.location.hostname;
  useEffect(() => {
    const isSetupCompleted = localStorage.getItem("isSetupCompleted");
    if (isSetupCompleted == "true") {
      navigate("/landingpage");
    }
  }, []);

  const onGetStartedClick = () => {
    navigate("/setupwizardstart");
  };
  const onSkipButtonClick = (e: any) => {
    skipBtnRef.current?.toggle(e);
  };
  const handleSkip = () => {
    if (skipBtnRef.current) {
      skipBtnRef.current.hide();
    }
  };

  return (
    <div className="startup-page">
      <Toast ref={toast} />
      <Header pagename="setup-start" />
      <div className="content-wrapper container-fluid landing-content-wrapper d-flex justify-content-center setup-landing-page">
        <div className="row mx-auto wrapper-below-div">
          <div className="col-12 text-center text-light ">
            {loading ? (
              <div>
                <AtkLoader />
              </div>
            ) : (
              <div className="setup-info-div">
                <h3 className=" text-light ">
                  Thank you for choosing {hostname == process.env.REACT_APP_Momentum_Url1 || hostname === process.env.REACT_APP_Momentum_Url2 ? "Momentum ToolBox" : "InsuranceGIG"}{" "}
                  {window.innerWidth > 767 ? "website" : "chrome extension"}; in this quick setup wizard, we'll take you through the essential steps
                  for you to get started.{" "}
                </h3>
                <div className="get-started-div">
                  <span className="started-btn" onClick={onGetStartedClick}>
                    Get Started
                  </span>
                </div>

                <span className="skip-link" onClick={onSkipButtonClick}>
                  Skip Setup Wizard
                </span>

                <OverlayPanel ref={skipBtnRef} className="menu-panel ">
                  <Skip onCancelButtonClick={handleSkip} />
                </OverlayPanel>
              </div>
            )}
          </div>
        </div>
      </div>
      
    </div>
  );
}
