import React, { useEffect, useRef, useState } from "react";
import AtkButton from "../AtlaskitControls/AtkButton";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import AtkMessage from "../AtlaskitControls/AtkMessage";
import AtkTextField from "../AtlaskitControls/AtkTextField";
import { useNavigate } from "react-router-dom";
import CommonValues from "../common/utils";
import axios from "axios";
import { Toast } from "primereact/toast";
import WatchFilledIcon from "@atlaskit/icon/glyph/watch-filled";
import WatchIcon from "@atlaskit/icon/glyph/watch";

export default function UsPfSettings() {
  const [loading, setLoading] = useState(false);
  const [usPfLoading, setUSPfLoading] = useState(false);
  const [deleteUSPfLoading, setDeleteUSPfLoading] = useState(false);
  const [errorMessageUsername, setErrorMessageUsername] = useState("");
  const [errorMessagePassword, setErrorMessagePassword] = useState("");
  const [errorMessageSearchCode, setErrorMessageSearchCode] = useState("");
  const [usPassword, setUsPassword] = useState("");
  const [searchCode, setSearchCode] = useState("");
  const [usUsername, setUsUsername] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const toast: any = useRef("");
  const navigate = useNavigate();
  const [showIGCredsMsg, setShowIGCredsMsg] = useState(false);

  useEffect(() => {}, []);
  const getUSPfCreds = () => {
    setLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/uspfcreds`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        if (response.status == 204) {
          setUsUsername("");
          setSearchCode("");
          setUsUsername("");
          setShowIGCredsMsg(true);
        } else {
          setShowIGCredsMsg(false);
          setUsUsername(response.data.username);
          setSearchCode(response.data.searchCode);
          if (response.data.username !== null && response.data.username !== "" && response.data.password === "") {
            setUsPassword("*********");
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the US Premium Finance Setting details";
          }
        } else {
          errorMessage = "Unknown error while loading the US Premium Finance Setting details";
        }
        toast.current.show({ severity: "error", detail: { errorMessage } });

        setLoading(false);
      });
  };
  const onUsernameChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setUsUsername(e.target.value);
    setSearchCode(e.target.value);
  };

  const onPasswordChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setUsPassword(e.target.value);
  };
  const onSearchCodeChange = (e: any) => {
    setSearchCode(e.target.value);
  };
  const onShowPassword = () => {
    if (passwordType == "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const onKeyUpdateUSPfClick = (e: any) => {
    if (e.keyCode == 13) {
      onUpdateUSPfClick();
    }
  };
  const uspfCheckValidation = () => {
    let formIsValid = true;
    setErrorMessageUsername("");
    setErrorMessagePassword("");
    setErrorMessageSearchCode("");

    if (usUsername == "" || usUsername == null) {
      formIsValid = false;
      setErrorMessageUsername("Please enter user name");
    }
    // if (usPassword == "" || usPassword == null) {
    //   formIsValid = false;
    //   setErrorMessagePassword("Please enter password");
    // }

    return formIsValid;
  };
  const onUpdateUSPfClick = () => {
    if (uspfCheckValidation()) {
      setUSPfLoading(true);
      let token = CommonValues.GetToken();
      var data = JSON.stringify({
        Username: usUsername,
        Password: usPassword,
        SearchCode: searchCode,
      });

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_UserService_Url}/api/user/uspfcreds`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((response: any) => {
          setUSPfLoading(false);
          toast.current.show({ severity: "success", detail: "US Premium Finance Settings updated successfully" });
          getUSPfCreds();
        })
        .catch((error: any) => {
          console.log(error);
          let errorMessage = "";
          if (error.response != null) {
            if (error.response.status == 401) {
              CommonValues.Logout(navigate);
            } else if (error.response.status == 409) {
              toast.current.show({
                severity: "error",
                detail: "It look likes you are not registered with US Premium Finance, Please register first.",
              });
            } else {
              toast.current.show({ severity: "error", detail: "Unknown error while updating the US Premium Finance Settings" });
            }
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while updating the US Premium Finance Settings" });
          }

          setUSPfLoading(false);
        });
    }
  };
  const onDeleteUSPfClick = () => {
    setErrorMessageUsername("");
    setErrorMessagePassword("");
    setDeleteUSPfLoading(true);
    let token = CommonValues.GetToken();
    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/uspfcreds`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setDeleteUSPfLoading(false);
        toast.current.show({ severity: "success", detail: "US Premium Finance Settings deleted successfully" });
        getUSPfCreds();
      })
      .catch((error: any) => {
        console.log(error);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while deleting the US Premium FinanceSettings";
          }
        } else {
          errorMessage = "Unknown error while deleting the US Premium Finance Settings";
        }
        toast.current.show({ severity: "error", detail: { errorMessage } });
        setDeleteUSPfLoading(false);
      });
  };
  return (
    <div className="row mx-auto mt-3 d-flex align-items-center setting-setup-width">
      <Toast ref={toast} />
      {loading ? (
        <div className="d-flex justify-content-center">
          <AtkLoader />
        </div>
      ) : (
        <>
          <div className="form-group col-4  mb-2">
            <label className="form-label">
              Username
              <span className="text-danger">*</span>
            </label>
          </div>
          <div className={errorMessageUsername.length > 0 ? " col-8  mb-2 validation-div" : " col-8  mb-2"}>
            <AtkTextField
              className="input-field"
              type="text"
              id="username"
              name="username"
              onChange={onUsernameChange}
              value={usUsername}
              onKeyDown={onKeyUpdateUSPfClick}
              placeholder="Enter user name"
            />
            <span className="text-danger text-12">{errorMessageUsername}</span>
          </div>

          <div className="form-group col-4  ">
            <label className="form-label">
              Password
              {/* <span className="text-danger">*</span> */}
            </label>
          </div>
          <div className={errorMessagePassword.length > 0 ? " col-8   validation-div position-relative" : " col-8  position-relative"}>
            <AtkTextField
              className="input-field"
              type={passwordType}
              id="headerKey"
              name="headerKey"
              onChange={onPasswordChange}
              value={usPassword}
              onKeyDown={onKeyUpdateUSPfClick}
              autoComplete="new-password"
              placeholder="Enter password"
            />
            <span className="eye-icon" onClick={onShowPassword}>
              {passwordType === "password" ? <WatchFilledIcon label="Pause" /> : <WatchIcon label="Play" />}
            </span>
          </div>
          {/* {errorMessagePassword.length > 0 ? (
            <>
              <div className="col-4  "></div>
              <div className=" col-8 ">
                <span className="text-danger text-12">{errorMessagePassword}</span>
              </div>
            </>
          ) : null} */}
          <div className="form-group col-4  mb-2 ">
            <label className="form-label">Search Code</label>
          </div>
          <div className={errorMessageSearchCode.length > 0 ? " col-8  mb-2 validation-div mt-2" : " col-8  mb-2 mt-2"}>
            <AtkTextField
              className="input-field"
              type="text"
              id="ownerSearchCode"
              name="ownerSearchCode"
              onChange={onSearchCodeChange}
              value={searchCode}
              onKeyDown={onKeyUpdateUSPfClick}
              placeholder="Enter search code"
            />
            <span className="text-danger text-12">{errorMessageSearchCode}</span>
          </div>

          <div className="col-12  text-end mb-2 mt-2">
            {deleteUSPfLoading ? (
              <AtkLoader></AtkLoader>
            ) : (
              <AtkButton label="Reset" onClick={onDeleteUSPfClick} className="pf-secondary-btn w-auto me-2" />
            )}

            {usPfLoading ? <AtkLoader></AtkLoader> : <AtkButton label="Save" onClick={onUpdateUSPfClick} className="pf-primary-btn w-auto " />}
          </div>
          <div className="col-md-12 mb-2">
            {showIGCredsMsg ? <AtkMessage appearance="information" messageText="Using InsuranceGIG Credentials" /> : null}
          </div>
        </>
      )}
    </div>
  );
}
