import React, { FC, useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DownloadIcon from "../../assets/images/icon _download_.png";
import CommonValues from "../../common/utils";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import { Toast } from "primereact/toast";
import moment from "moment";
import feedbackImg from "../../assets/images/feedback.svg";
import { OverlayPanel } from "primereact/overlaypanel";
import AtkButton from "../../AtlaskitControls/AtkButton";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import COIScanSidebar from "./COIScanSidebar";
import COIScanMiddleHeader from "./COIScanMiddleHeader";
import PreviewIcon from "../../assets/images/ViewDetailsIcon.png";
import { Dialog } from "primereact/dialog";

export default function COIHistory(props: any) {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const feedbackRef = useRef<OverlayPanel>(null);
  const [historyloading, setHistoryLoading] = useState(true);
  const [coihistorydata, setCOIHistoryData] = useState<any>([]);
  const [downloadloading, setDownloadLoading] = useState(false);
  const [submissionId, setSubmissionId] = useState();
  const [feedBackData, setFeedBackData] = useState<any>([]);
  const [feedBackError, setFeedBackError] = useState("");
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [previewFileLoading, setPreviewFileLoading] = useState(false);
  const [extractedData, setExtractedData] = useState<any>([]);


  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
  }, []);
  
  useEffect(() => {
    getCOIHistory();
  }, []);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const getCOIHistory = () => {
    setHistoryLoading(true);
    const token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/getcoihistory`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setCOIHistoryData(response.data);
        setHistoryLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while getting coi history", life: 3000 });
          }
        }
        setHistoryLoading(false);
      });
  };
  const onDownloadClick = (rowData: any) => {
    setDownloadLoading(true);
    let submissionId = rowData.submissionId;
    setSubmissionId(submissionId);
    var token = CommonValues.GetToken();
    axios({
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/downloadcsvcoifile/${rowData.submissionId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response: any) => {
        const link = document.createElement("a");
        link.href = response.data;
        link.click();
        link.remove();
        setDownloadLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while downloading the file", life: 3000 });
          }
        }
        setDownloadLoading(false);
      });
  };

  const onPreviewClick = (rowData: any) => {
    setShowPopUp(true);
    setPreviewFileLoading(true);
    setExtractedData(JSON.parse(rowData.responseJson));
    const token = CommonValues.GetToken();
    var data = {
      TransactionId: rowData.submissionId,
      FileName: rowData.fileName,
      Target: "CoiScan"
    };
    axios({
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/previewdocument`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
      .then((response: any) => {
        setPdfUrl(response.data.fileUrl);
        setExtractedData(JSON.parse(response.data.csvData))
        setPreviewFileLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while getting  history details", life: 3000 });
          }
        }
        setPreviewFileLoading(false);
      });
  };
 
  const onSaveRatings = (rowData: any) => {
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    const token = CommonValues.GetToken();
    let data = {
      TransactionId: rowData.submissionId,
      Feedback:feedbackVal,
      IsLiked: rowData.isLiked,
    };
    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/coiscan/isliked`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", detail: "Ratings Submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: rowData.isLiked };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while submitting ratings", life: 3000 });
          }
        }
      });
  };

  const feedbackTemplate = (rowData: any) => {
    return <img src={feedbackImg} className=" show-cursor" onClick={(e) => onShowFeedbackPopup(e, rowData)} title="Feedback" />;
  };

  const onShowFeedbackPopup = (e: any, rowData: any) => {
    setFeedBackError("");
    const feedback = { ...rowData, feedback: "" };
    setFeedBackData(feedback);
    feedbackRef.current?.toggle(e);
  };

  const onFeedbackChange = (newFeedback: string) => {
    const feedback = { ...feedBackData, feedback: newFeedback };
    setFeedBackData(feedback);
    if(newFeedback.length == 0 || newFeedback.trim() === "") {
      setFeedBackError("Please enter your feedback");
    } else if (newFeedback.length > 0) {
      setFeedBackError("");
    }
  };

  const onThumbsUpClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === true) {
      liked = null;
    } else {
      liked = true;
    }
    rowData = { ...rowData, isLiked: liked };
    setFeedBackData(rowData);
    setCOIHistoryData((prevResponses: any) =>
      prevResponses.map((response: any) => (response.submissionId === rowData.submissionId ? { ...response, isLiked: liked } : response))
    );
    onSaveRatings(rowData);
  };

  const onThumbDownClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === false) {
      liked = null;
    } else {
      liked = false;
    }
    rowData = { ...rowData, isLiked: liked };
    setFeedBackData(rowData);
    onSaveRatings(rowData);
    setCOIHistoryData((prevResponses: any) =>
      prevResponses.map((response: any) => (response.submissionId === rowData.submissionId ? { ...response, isLiked: liked } : response))
    );
  };

  const onHideFeedbackPopup = () => {
    if (feedbackRef.current) {
      feedbackRef.current.hide();
      setFeedBackData([]);
    }
  };

  const onSubmitFeedbackClick = (rowData: any) => {
    if (rowData.feedback == null || rowData.feedback == "") {
      setFeedBackError("Please enter your feedback");
    } else {
      setFeedBackError("");
      setFeedbackLoading(true);
      setSubmissionId(rowData.submissionId);
      onSaveFeedback(rowData);
    }
  };

  const onSaveFeedback = (rowData: any) => {
    const token = CommonValues.GetToken();
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    let data = {
      TransactionId: rowData.submissionId,
      Feedback: feedbackVal,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/OCR/coiscan/feedback`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", detail: "Feedback Submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: null };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while submitting feedback", life: 3000 });
          }
        }
      });
  };

  const statusTemplate = (rowData: any) => {
    return rowData.status === "Started" ? (
      <b className="text-warning">Started </b>
    ) : rowData.status === "Inprogress" ? (
      <b className="text-info"> In Progress</b>
    ) : rowData.status === "CsvCreated" ? (
      <b className="text-info"> CsvCreated</b>
    ) : rowData.status === "Completed" ? (
      <b className="text-success"> Completed</b>
    ) : rowData.status === "OccuredError" ? (
      <b className="text-danger"> Failed</b>
    ) : null;
  };


  const actionTemplate = (rowData: any) => {
    return rowData.status === "Completed" ? (
      <>
        <img src={DownloadIcon} onClick={() => onDownloadClick(rowData)} title="Download CSV" className="me-2 show-cursor" />
        <img src={PreviewIcon} height={20} onClick={() => onPreviewClick(rowData)} title="Preview" className="show-cursor" />
      </>
    ) :
      (
        <>
          <span className=" text-12 quote-not-span">Not Available</span>
        </>
      );
  };

  const onHidePopupClicked = () => {
    setShowPopUp(false);
    setPdfUrl("");
  };

  const descriptionOfOperation =(rowData:any)=>{
    let descriptionOfOperation= rowData.DescriptionOfOperation
    return ( 
    <span className=" text-12" title={rowData.DescriptionOfOperation}>{descriptionOfOperation.substring(0,16) + "..."}</span>
    )
  }

  return (
    <>
      <Toast ref={toast} />
      <div className="coi-scan-history-main-page">
        <Header pagename="coi-scan-history" />
        <div className="content-wrapper ">
          <div className="row  wrapper-main-row mx-auto wrapper-below-div">
            <div className="col-12">
              <COIScanMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <COIScanSidebar pagename="coi-scan-history" />
              </div>

              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row  mt-2 d-flex  align-items-end text-12 position-relative">
                  <div className="col-12 mt-2  text-end ">
                    <AtkButton label="Refresh" onClick={getCOIHistory} className=" pf-primary-btn w-auto" />
                  </div>
                  <div className="col-12  history-table mt-3">
                    {historyloading ?
                      <div className="d-flex align-ietms-center justify-content-center overflow-hidden">
                        <AtkLoader />{" "}
                      </div> :
                      <DataTable
                        value={coihistorydata}
                        paginator
                        paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                        rows={10}
                        selectionMode="single"
                      >
                        <Column header="File Name" field="fileName" sortable></Column>
                        <Column
                          header="Submitted Date"
                          field="created"
                          body={(rowData) => {
                            const utcdate = moment.utc(rowData.created);
                            var localDate = moment(utcdate).local();
                            return <span className="">{localDate.format("MM-DD-YYYY HH:mm")} </span>;
                          }}
                          sortable
                        ></Column>

                        <Column header="Status" field="status" body={statusTemplate}></Column>
                        <Column header="Action" body={actionTemplate} ></Column>
                        <Column header="Feedback" body={feedbackTemplate}></Column>

                      </DataTable>
                    }
                  </div>
                  <Dialog header="" visible={showPopUp} style={{ width: "80vw", height: "90vh" }} onHide={onHidePopupClicked} draggable={false}>
                    {previewFileLoading ? (
                      <div className="d-flex align-items-center justify-content-center overflow-hidden">
                        <AtkLoader />
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-6 border-end">
                          <b>Uploaded PDF</b>
                          <iframe src={pdfUrl} style={{ overflow: "scroll", height: "75vh", width: "100%" }} className="mt-2"></iframe>
                        </div>
                        <div className="col-6 history-table">
                          <div className="mt-2">
                            <b>Extracted data</b>
                            {extractedData && extractedData.length > 0 ? (
                              <div className="mt-2">
                                <DataTable value={extractedData} paginator paginatorTemplate="PrevPageLink PageLinks NextPageLink" rows={10}>
                                  <Column header="Holdername" field="Holdername" style={{fontSize:"10px"}}></Column>
                                  <Column header="CareOf" field="CareOf" style={{fontSize:"10px"}}></Column>
                                  <Column header="AddressLine1" field="AddressLine1" style={{fontSize:"10px"}}></Column>
                                  <Column header="AddressLine2" field="AddressLine2" style={{fontSize:"10px"}}></Column>
                                  <Column header="City" field="City" style={{fontSize:"10px"}}></Column>
                                  <Column header="State" field="State" style={{fontSize:"10px"}}></Column>
                                  <Column header="ZipCode" field="ZipCode" style={{fontSize:"10px"}}></Column>
                                  <Column header="InsuredName" field="InsuredName"style={{fontSize:"10px"}}></Column>
                                  <Column header="DescriptionOfOperation" body={descriptionOfOperation} style={{fontSize:"10px"}}></Column>
                                  <Column header="GLPolicyNumber" field="GLPolicyNumber" style={{fontSize:"10px"}}></Column>
                                  <Column header="GLEffectiveDate" field="GLEffectiveDate" style={{fontSize:"10px"}}></Column>
                                  <Column header="GLExpiryDate" field="GLExpiryDate" style={{fontSize:"10px"}}></Column>
                                  <Column header="GLAddInt" field="GLAddInt" style={{fontSize:"10px"}}></Column>
                                  <Column header="GLWS" field="GLWS" style={{fontSize:"10px"}}></Column>
                                  <Column header="ALPolicyNumber" field="ALPolicyNumber" style={{fontSize:"10px"}}></Column>
                                  <Column header="ALEffectiveDate" field="ALEffectiveDate" style={{fontSize:"10px"}}></Column>
                                  <Column header="ALExpiryDate" field="ALExpiryDate" style={{fontSize:"10px"}}></Column>
                                  <Column header="ALAddInt" field="ALAddInt" style={{fontSize:"10px"}}></Column>
                                  <Column header="ALWS" field="ALWS" style={{fontSize:"10px"}}></Column>
                                  <Column header="UMBRPolicyNumber" field="ALWUMBRPolicyNumberS" style={{fontSize:"10px"}}></Column>
                                  <Column header="UMBREffectiveDate" field="UMBREffectiveDate" style={{fontSize:"10px"}}></Column>
                                  <Column header="UMBRExpiryDate" field="UMBRExpiryDate" style={{fontSize:"10px"}}></Column>
                                  <Column header="UMBRAddInt" field="UMBRAddInt" style={{fontSize:"10px"}}></Column>
                                  <Column header="UMBRWS" field="UMBRWS" style={{fontSize:"10px"}}></Column>
                                  <Column header="WorkCompPolicyNumber" field="WorkCompPolicyNumber" style={{fontSize:"10px"}}></Column>
                                  <Column header="WorkCompEffectiveDate" field="WorkCompEffectiveDate" style={{fontSize:"10px"}}></Column>
                                  <Column header="WorkCompExpiryDate" field="WorkCompExpiryDate" style={{fontSize:"10px"}}></Column>
                                  <Column header="WorkCompAddInt" field="WorkCompAddInt" style={{fontSize:"10px"}}></Column>
                                  <Column header="WorkCompWS" field="WorkCompWS" style={{fontSize:"10px"}}></Column>
                                  <Column header="GLCarrierName" field="GLCarrierName" style={{fontSize:"10px"}}></Column>
                                  <Column header="GLNaicCode" field="GLNaicCode" style={{fontSize:"10px"}}></Column>
                                  <Column header="ALCarrierName" field="ALCarrierName" style={{fontSize:"10px"}}></Column>
                                  <Column header="ALNaicCode" field="ALNaicCode" style={{fontSize:"10px"}}></Column>
                                  <Column header="UMBRCarrierName" field="UMBRCarrierName" style={{fontSize:"10px"}}></Column>
                                  <Column header="UMBRNaicCode" field="UMBRNaicCode" style={{fontSize:"10px"}}></Column>
                                  <Column header="WCCarrierName" field="WCCarrierName" style={{fontSize:"10px"}}></Column>
                                  <Column header="WCNaicCode" field="WCNaicCode" style={{fontSize:"10px"}}></Column>
                                </DataTable>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    )}
                  </Dialog>
                  <OverlayPanel ref={feedbackRef} className="commission-feedback-panel feedback-panel">
                    <div className="row mx-auto d-flex align-items-center mb-2">
                      <div className="col-12 feedback-header">
                        <h4>Feedback</h4>
                      </div>
                      <div className={"form-group col-12 mb-2 text-12"}>
                        <textarea
                          name="feedback"
                          onChange={(e) => onFeedbackChange(e.target.value)}
                          value={feedBackData.feedback}
                          className={`form-control comment-text ${feedBackError.length > 0 ? " validation-div " : ""}`}
                          rows={4}
                          placeholder="Enter your feedback here"
                        />
                        <span className="text-danger  text-12 mt-1">{feedBackError}</span>
                      </div>
                      <div className="col-4 d-flex justify-content-evenly">
                        <i
                          className={`show-cursor fa fa-thumbs-up commission-feedback-icon 
               ${feedBackData.isLiked == true ? " active" : " "}`}
                          aria-hidden="true"
                          onClick={(e) => onThumbsUpClick(feedBackData)}
                        ></i>
                        <i
                          className={`show-cursor fa fa-thumbs-down commission-feedback-icon 
                ${feedBackData.isLiked == false ? " active" : " "}`}
                          aria-hidden="true"
                          onClick={(e) => onThumbDownClick(feedBackData)}
                        ></i>
                      </div>
                      <div className="col-4 text-end p-2 pe-0  ">
                        <AtkButton label="Cancel" onClick={onHideFeedbackPopup} className="pf-secondary-btn w-auto" />
                      </div>
                      <div className="col-4 text-end p-2  ">
                        {submissionId == feedBackData.submissionId && feedbackLoading ? (
                          <div className="d-flex justify-content-center align-items-center">
                            <AtkLoader />
                          </div>
                        ) : (
                          <AtkButton label="Submit" onClick={() => onSubmitFeedbackClick(feedBackData)} className="pf-secondary-btn w-auto " />
                        )}
                      </div>
                    </div>
                  </OverlayPanel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
}
