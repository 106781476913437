import { useState, useEffect } from "react";
import FinalQuotes from "./FinalQuotes";
import ResponseButton from "./ResponseButton";
import CommonValues from "../../common/utils";
import AtkMessage from "../../AtlaskitControls/AtkMessage";
import axios from "axios";
import { Skeleton } from "primereact/skeleton";
import { useNavigate } from "react-router-dom";

export default function GotoPFResponse(props: any) {
  const [inputLoading, setInputLoading] = useState(false);
  const [inputPremiumFinanceResponse, setInputPremiumFinanceResponse] = useState<any>({});
  const [GotoPfShowResponse, setGotoPfShowResponse] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [saveQuoteErrorMessage, setSaveQuoteErrorMessage] = useState("");
  const [saveQuoteSuccessMessage, setSaveQuoteSuccessMessage] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [rowData, setRowData] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    let token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    setTimeout(() => {
      GotoPfSubmitClick();
    }, 5);
  }, []);

  const GotoPfSubmitClick = () => {
    let subPolicies: any = [];
    Array.from(props.coverageCode).forEach((code: any, index: any) => {
      subPolicies.push({
        number: props.policyNumber[index],
        coverageCode: code.inputCode == null ? "*" : code.inputCode,
        policyId: props.nCPolicyId,
        effectiveDate: props.effectiveDate[index],
        expirationDate: "",
        term: props.term[index],
        BrokerFee: props.brokerFee[index] == null || props.brokerFee[index] === "" ? "0" : props.brokerFee[index],
        premium: props.currentPremiumsArray[index] ? props.currentPremiumsArray[index].toString() : 0,
        earnedTaxesFees: "0",
        financedTaxesFees: "0",
        otherFees: props.fees[index] != undefined ? props.fees[index].toString() : "0",
        otherTaxes: props.taxes[index] != undefined ? props.taxes[index].toString() : "0",
        minimumEarnedPercent: props.minimumEarned[index] != undefined ? props.minimumEarned[index] : "0",
        policyType: "commercial",
        index: props.selectedPolicyIndex ? props.selectedPolicyIndex : 0,
        carrier: {
          id: props.carrierId,
          name: props.carrier[index].description,
          address: {
            address1: props.carrierAddress[index],
            address2: "",
            city: props.carrierCity[index],
            state: props.carrierState[index],
            zip: props.carrierZip[index],
            country: props.carrierCountry,
            unit: "",
          },
        },
        generalAgency: {
          name: props.gaName[index],
          address: {
            address1: props.gaAddress[index],
            address2: "",
            city: props.gaCity[index],
            state: props.gaState[index],
            zip: props.gaZip[index],
            country: props.gaCountry[index],
            unit: "",
          },
        },
      });
    });

    var token = CommonValues.GetToken();
    var data = JSON.stringify({
      target: "PFIP1",
      data: {
        ClientName: props.firstName + " " + props.lastName,
        ClientId: props.clientId.toString(),
        IsIndicative: localStorage.getItem("IsIndicative"),        
        insured: {
          insuredId: props.nCInsuredId,
          name: props.businessProfile,
          dbaName: "",
          naics: "",
          phoneNumber: props.businessPhoneNumber != "" ? props.businessPhoneNumber : "000",
          email: props.businessEmail,
          address: {
            address1: props.addLine1,
            address2: props.addLine2,
            city: props.city,
            state: props.sstate,
            zip: props.zipCode,
            country: props.country,
            unit: "",
          },
        },
        agent: {
          id: "",
          firstName: props.agentName,
          lastName: "",
          email: "",
          phoneNumber: props.agentPhoneNumber,
          address: {
            address1: props.agentAddress,
            address2: "",
            city: props.agentCity,
            state: props.agentState,
            zip: props.agentZip,
            country: props.agentCountry,
            unit: "",
          },
        },
        policy: subPolicies,
      },
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/quote`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    setInputLoading(true);
    axios(config)
      .then((response: any) => {
        window.scroll(0, 0);
        setInputPremiumFinanceResponse(response.data);
        if (response.data.quoteNumber == null || response.data.quoteNumber == "" || response.data.quoteNumber == "0" || response.status == 204) {
          setGotoPfShowResponse(true);
          setResponseMessage(response.data.message);
        } else {
          setGotoPfShowResponse(false);
        }
        let responseData = {
          transactionId: response.data.transactionId,
          url: "",
          provider: "gotoPremiumFinance",
          targetPartner: "gotoPremiumFinance",
          isSaved: false,
          quoteNumber: response.data.quoteNumber,
          ncPolicyNumber: props.ncPolicyNumber,
          policyId: props.nCPolicyId,
          insuredId: props.nCInsuredId,
          policyNumber: props.policyNumber,
          clientId: props.clientId,
          selectedPolicyIndex: props.selectedPolicyIndex,
          responseMessage: response.data.message,
          premiumresponse: response.data,
          tenantId: localStorage.getItem("tenantId"),
        };
        setRowData(responseData);

        setInputLoading(false);
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while submitting the details for gotopremium finance";
          }
        } else {
          errorMessage = "Unknown error while submitting  details for GotoPf";
        }
        setGotoPfShowResponse(false);
        setInputLoading(false);
        setErrorMessage(errorMessage);
        setResponseMessage(errorMessage);
      });
  };
  return (
    <div className=" quote-response-block">
      <h4 className="quote-heading">
        <img src={props.gotoLogoUrl} width="25" height="25" /> Goto Premium Financing Quote
      </h4>
      <div className="quote-middle-block">
        {inputLoading ? (
          
          <Skeleton width="100%" height="15rem"></Skeleton>
        ) : (
          <>
            {GotoPfShowResponse ? (
              <div className="my-2 p-2">
                <AtkMessage appearance="warning" messageText={responseMessage} />
              </div>
            ) : (
              <>
                {/* <div className=""> */}
                <FinalQuotes premiumresponse={inputPremiumFinanceResponse} />
                <div className="response-btn-div ">
                  <ResponseButton rowData={rowData} />
                </div>
                {/* </div> */}
              </>
            )}
          </>
        )}

        {saveQuoteErrorMessage.length > 0 ? (
          <div className="my-2 p-2">
            <AtkMessage appearance="error" messageText={saveQuoteErrorMessage} />
          </div>
        ) : null}
        {saveQuoteSuccessMessage.length > 0 ? (
          <div className="my-2 p-2">
            <AtkMessage appearance="success" messageText={saveQuoteSuccessMessage} />
          </div>
        ) : null}
      </div>
    </div>
  );
}