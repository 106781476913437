import LoginPage from "../Account/Login";
import { Navigate, useNavigate } from "react-router-dom";

export const Logout = (navigate: any) => {
  localStorage.removeItem("igtoken");
  localStorage.removeItem("AMS_ADD");
  localStorage.removeItem("StoreNaicsCodesNM");
  localStorage.removeItem("StoreNaicsCodes");
  localStorage.removeItem("tenantId");
  localStorage.removeItem("policyData");
  localStorage.removeItem("client");
  localStorage.removeItem("currentPage");
  localStorage.removeItem("AMS");
  localStorage.removeItem("NCToken");
  localStorage.removeItem("NCAgencyId");
  localStorage.removeItem("st");
  localStorage.removeItem("SelectedAMS");
  localStorage.removeItem("isNewPolicy");
  localStorage.removeItem("IsIndicative");
  localStorage.removeItem("userName");
  localStorage.removeItem("clientCount");
  localStorage.removeItem("HawksoftAgencyId");
  localStorage.removeItem("PFSettings");
  localStorage.removeItem("isSetupCompleted");
  localStorage.removeItem("SocialLogin");
  localStorage.removeItem("NowCertsSettings");
  localStorage.removeItem("VertaforeSetting");
  localStorage.removeItem("policyIds");
  localStorage.removeItem("NowcertsAgentId");

  navigate("/");
};

class CommonValues {
  static Logout = (navigate: any) => {
    localStorage.removeItem("igtoken");
    localStorage.removeItem("AMS_ADD");
    localStorage.removeItem("StoreNaicsCodesNM");
    localStorage.removeItem("StoreNaicsCodes");
    localStorage.removeItem("tenantId");
    localStorage.removeItem("policyData");
    localStorage.removeItem("client");
    localStorage.removeItem("currentPage");
    localStorage.removeItem("AMS");
    localStorage.removeItem("NCToken");
    localStorage.removeItem("NCAgencyId");
    localStorage.removeItem("st");
    localStorage.removeItem("SelectedAMS");
    localStorage.removeItem("isNewPolicy");
    localStorage.removeItem("IsIndicative");
    localStorage.removeItem("userName");
    localStorage.removeItem("clientCount");
    localStorage.removeItem("HawksoftAgencyId");
    localStorage.removeItem("PFSettings");
    localStorage.removeItem("isSetupCompleted");
    localStorage.removeItem("SocialLogin");
    localStorage.removeItem("NowCertsSettings");
    localStorage.removeItem("VertaforeSetting");
    localStorage.removeItem("OrgName");
    localStorage.removeItem("RoleId");
    localStorage.removeItem("AvtarName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("slugURL");
    localStorage.removeItem("LastName");
    localStorage.removeItem("IconURL");
    localStorage.removeItem("Mode");
    localStorage.removeItem("HasGIGMarket");
    localStorage.removeItem("HeaderText");
    localStorage.removeItem("TenantId");
    localStorage.removeItem("userId");
    localStorage.removeItem("policyIds");
    localStorage.removeItem("NowcertsAgentId");

    navigate("/");
    // var url = window.location.href;
    // if (url.indexOf("popup.html") > -1) {
    //     window.history.replaceState(null, "", "/popup.html")
    // }
    // else {
    //     window.history.replaceState(null, "", "/newtab.html")
    // }
  };
  static SetValues = (
    token: any,
    tenantId: any,
    userName: any,
    userId: any,
    companyName: any,
    roleId: any,
    avatarName: any,
    firstName: any,
    lastName: any,
    iconURL: any,
    hasGIGMarket: any,
    slugURL: any,
    headerText: any
  ) => {
    localStorage.setItem("igtoken", token);
    localStorage.setItem("tenantId", tenantId);
    localStorage.setItem("userName", userName);
    localStorage.setItem("userId", userId);
    localStorage.setItem("OrgName", companyName);
    localStorage.setItem("RoleId", roleId);
    localStorage.setItem("AvtarName", avatarName);
    localStorage.setItem("FirstName", firstName);
    localStorage.setItem("LastName", lastName);
    localStorage.setItem("IconURL", iconURL);
    localStorage.setItem("HasGIGMarket", hasGIGMarket);
    localStorage.setItem("slugURL", slugURL);
    localStorage.setItem("HeaderText", headerText);
  };
  static GetToken = () => {
    return localStorage.getItem("igtoken");
  };
  static GetWorkemail = () => {
    return localStorage.getItem("userName");
  };
  static GetTenantId = () => {
    return localStorage.getItem("tenantId");
  };

  static SetToken = (token: any) => {
    localStorage.setItem("igtoken", token);
  };

  static SetTenantId = (tenantId: any) => {
    localStorage.setItem("tenantId", tenantId);
  };
  static GetNCToken = () => {
    return localStorage.getItem("NCToken");
  };
  static GetNCAgencyId = () => {
    return localStorage.getItem("NCAgencyId");
  };
  static GetUserId = () => {
    localStorage.getItem("userId");
  };

  static ClearAllValues = () => {
    localStorage.removeItem("igtoken");
    localStorage.removeItem("AMS_ADD");
    localStorage.removeItem("StoreNaicsCodesNM");
    localStorage.removeItem("StoreNaicsCodes");
    localStorage.removeItem("tenantId");
    localStorage.removeItem("policyData");
    localStorage.removeItem("client");
    localStorage.removeItem("currentPage");
    localStorage.removeItem("AMS");
    localStorage.removeItem("NCToken");
    localStorage.removeItem("NCAgencyId");
    localStorage.removeItem("st");
    localStorage.removeItem("SelectedAMS");
    localStorage.removeItem("isNewPolicy");
    localStorage.removeItem("IsIndicative");
    localStorage.removeItem("userName");
    localStorage.removeItem("clientCount");
    localStorage.removeItem("HawksoftAgencyId");
    localStorage.removeItem("PFSettings");
    localStorage.removeItem("isSetupCompleted");
    localStorage.removeItem("SocialLogin");
    localStorage.removeItem("NowCertsSettings");
    localStorage.removeItem("VertaforeSetting");
    localStorage.removeItem("OrgName");
    localStorage.removeItem("RoleId");
    localStorage.removeItem("AvtarName");
    localStorage.removeItem("FirstName");
    localStorage.removeItem("slugURL");
    localStorage.removeItem("LastName");
    localStorage.removeItem("IconURL");
    localStorage.removeItem("Mode");
    localStorage.removeItem("HasGIGMarket");
    localStorage.removeItem("HeaderText");
    localStorage.removeItem("TenantId");
    localStorage.removeItem("userId");
    localStorage.removeItem("NowcertsAgentId");
  };
}

export default CommonValues;
