import { useEffect, useState, useRef } from "react";
import CommonValues from "../../common/utils";
import AtkMessage from "../../AtlaskitControls/AtkMessage";
import axios from "axios";
import { Skeleton } from "primereact/skeleton";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import AtkButton from "../../AtlaskitControls/AtkButton";

export default function IpfsResponse(props: any) {
  const [inputLoading, setInputLoading] = useState(false);
  const [saveQuoteErrorMessage, setSaveQuoteErrorMessage] = useState("");
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [ipfsurl, setIPFSUrl] = useState("");
  const [inputPremiumFinanceResponse, setInputPremiumFinanceResponse] = useState<any>({});
  const toast: any = useRef("");

  useEffect(() => {
    let token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    setTimeout(() => {
      ipfsSubmitClick();
    }, 5);
  }, []);

  const ipfsSubmitClick = () => {
    let subPolicies: any = [];
    Array.from(props.coverageCode).forEach((code: any, index: any) => {
      subPolicies.push({
        number: props.policyNumber[index],
        coverageCode: code.ipfsCode == null ? "*" : code.ipfsCode,
        policyId: props.nCPolicyId,
        effectiveDate: props.effectiveDate[index],
        expirationDate: "",
        term: props.term[index],
        BrokerFee: props.brokerFee[index] == null || props.brokerFee[index] === "" ? "0" : props.brokerFee[index],
        premium: props.currentPremiumsArray[index] ? props.currentPremiumsArray[index].toString() : 0,
        earnedTaxesFees: "0",
        financedTaxesFees: "0",
        otherFees: props.fees[index] != undefined ? props.fees[index].toString() : "0",
        otherTaxes: props.taxes[index] != undefined ? props.taxes[index].toString() : "0",
        minimumEarnedPercent: props.minimumEarned[index] != undefined ? props.minimumEarned[index] : "0",
        policyType: "commercial",
        index: props.selectedPolicyIndex ? props.selectedPolicyIndex : 0,
        carrier: {
          id: props.carrier[index].ipfsCode,
          name: props.carrier[index].description,
          address: {
            address1: props.carrier[index].address1,
            address2: "",
            city: props.carrier[index].city,
            state: props.carrier[index].state,
            zip: props.carrier[index].zipCode,
            country: props.carrierCountry,
            unit: "",
          },
        },
        generalAgency: {
          name: props.gaName[index],
          searchCode: props.gaSearchCode[index],
          address: {
            address1: props.gaAddress[index],
            address2: "",
            city: props.gaCity[index],
            state: props.gaState[index],
            zip: props.gaZip[index],
            country: props.gaCountry[index],
            unit: "",
          },
        },
      });
    });

    var token = CommonValues.GetToken();
    var data = JSON.stringify({
      target: "IPFS",
      data: {
        ClientName: props.firstName + " " + props.lastName,
        IsIndicative: localStorage.getItem("IsIndicative"),
        ClientId: props.clientId.toString(),
        insured: {
          insuredId: props.nCInsuredId,
          name: props.businessProfile,
          dbaName: "",
          naics: "",
          phoneNumber: props.businessPhoneNumber != "" ? props.businessPhoneNumber : "000",
          email: props.businessEmail,
          address: {
            address1: props.addLine1,
            address2: props.addLine2,
            city: props.city,
            state: props.sstate,
            zip: props.zipCode,
            country: props.country,
            unit: "",
          },
        },
        agent: {
          id: "",
          firstName: props.agentName,
          lastName: "",
          email: "",
          phoneNumber: props.agentPhoneNumber,
          address: {
            address1: props.agentAddress,
            address2: "",
            city: props.agentCity,
            state: props.agentState,
            zip: props.agentZip,
            country: props.agentCountry,
            unit: "",
          },
        },
        policy: subPolicies,
      },
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/quote`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    setInputLoading(true);
    axios(config)
      .then((response: any) => {
        window.scroll(0, 0);
        if (response.data.documentURL == null || response.data.documentURL == "") {
          setErrorMessage(response.data.message == "" || response.data.message == null ? "Quote not available." : response.data.message);
        }
        setIPFSUrl(response.data.documentURL);
        setInputPremiumFinanceResponse(response.data);
        setInputLoading(false);
        setInputLoading(false);
      })
      .catch((error: any) => {
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while submitting  details for ipfs", life: 3000 });
          }
        } else {
          toast.current.show({ severity: "error", detail: "Unknown error while submitting  details for ipfs", life: 3000 });
        }
        setInputLoading(false);
        setErrorMessage("Unknown error while submitting  details for ipfs");
      });
  };

  const onContinueClick = () => {
    window.open(ipfsurl, "_blank");
  };
  return (
    <div className=" quote-response-block">
      <Toast ref={toast} />

      <h4 className="quote-heading">
        <img src={props.iPFSLogoUrl} width="25" height="25" /> IPFS
      </h4>
      <div className="quote-middle-block">
        {inputLoading ? (
          <Skeleton width="100%" height="15rem"></Skeleton>
        ) : (
          <>
            <div className="d-flex justify-content-center align-items-center p-2">
              {errorMessage.length == 0 ? (
                <AtkButton label="Continue" onClick={onContinueClick} className="pf-primary-btn w-100 my-2 " />
              ) : (
                <AtkMessage appearance="warning" messageText={errorMessage} />
              )}
            </div>
          </>
        )}
        {saveQuoteErrorMessage.length > 0 ? (
          <div className="my-2 p-2">
            <AtkMessage appearance="error" messageText={saveQuoteErrorMessage} />
          </div>
        ) : null}
      </div>
    </div>
  );
}
