import React from "react";
import Header from "../common/Header";
import Footer from "../common/Footer";
import PersonalDetails from "./PersonalDetails";
import PasswordDetails from "./PasswordDetails";
import CommonValues, { Logout } from "../common/utils";
import { Navigate, useNavigate } from "react-router-dom";
import  { FC, useEffect, useState, useRef } from "react";
export default function Details() {
  const navigate = useNavigate();
  const isSocialLogin = localStorage.getItem("SocialLogin") === "true" ? true : false;

  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }})

  return (
    <div className="profile-main-page">
      <Header />
      <div className="content-wrapper container-fluid landing-content-wrapper d-flex">
        <div className="wrapper-below-div bg-light profile-box">
          <div className="row mx-auto ">
            
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 text-center profile-border">
              <PersonalDetails />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12 text-center ">
            {isSocialLogin ? null : <PasswordDetails />}
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
}
