import { useEffect, useState, useRef } from "react";
import CommonValues from "../../../common/utils";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { InputSwitch } from "primereact/inputswitch";
import { Toast } from "primereact/toast";
import Toggle from "@atlaskit/toggle";

interface ChildProps {
  parentFunction: Function;
}

export default function PfCompanies(props: ChildProps) {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const [premiumLoading, setPremiumLoading] = useState(false);
  const [premiumSettings, setPremiumSettings] = useState<any>([]);
  const [saveSettingLoading, setSaveSettingLoading] = useState(false);
  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    getPremiumFinanceSettings();
  }, []);

  const getPremiumFinanceSettings = () => {
    setPremiumLoading(true);
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/premiumsetting`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setPremiumSettings(response.data);
        setTimeout(() => {
          getPremiumFinanceSettingsById(response.data);
        }, 200);
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the Premium finance Setting details";
          }
        } else {
          errorMessage = "Unknown error while loading the Premium finance Setting details";
        }
        toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
        setPremiumLoading(false);
      });
  };
  const getPremiumFinanceSettingsById = (premiumSettings: any) => {
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/pfcompanies`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        let technology: any = [];
        premiumSettings.forEach((item: any) => {
          if (response.data.some((res: any) => res.pfServiceId === item.id)) {
            technology.push({ id: item.id, name: item.name, isChecked: true });
          } else {
            technology.push({ id: item.id, name: item.name, isChecked: false });
          }
        });
        setPremiumSettings(technology);
        setPremiumLoading(false);
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the Premium finance Setting details by id";
          }
        }
        toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
        setPremiumLoading(false);
      });
  };
  const onSaveSettings = (premiumSettings: any) => {
    let premiumSettingsCurrent = [];
    for (let k = 0; k < premiumSettings.length; k++) {
      if (premiumSettings[k].isChecked == true) {
        premiumSettingsCurrent.push(premiumSettings[k]);
      }
    }

    setSaveSettingLoading(true);
    var token = CommonValues.GetToken();
    var data = JSON.stringify(premiumSettingsCurrent);
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/insertpfsettings`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then((response: any) => {
        toast.current.show({ severity: "success", detail: "Settings saved successfully", life: 3000 });
        props.parentFunction();
        setSaveSettingLoading(false);
        getCommonSettings();
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while inserting the Premium finance Setting details";
          }
        } else {
          errorMessage = "Unknown error while inserting the Premium finance Setting details";
        }
        setSaveSettingLoading(false);
        toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
      });
  };
  const getCommonSettings = () => {
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/pfcommonsettings`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
      },
    };
    axios(config)
      .then((response: any) => {
        if (response.data.agencyId != null && response.data.agencyId != "") {
          localStorage.setItem("HawksoftAgencyId", "true");
        } else {
          localStorage.setItem("HawksoftAgencyId", "false");
        }
        if (response.data.pfList == 0) {
          localStorage.setItem("PFSettings", "true");
        } else {
          localStorage.setItem("PFSettings", "false");
        }
        if (CommonValues.GetTenantId() == process.env.REACT_APP_Vertafore_Tenant_Id) {
          getVertaforeSettings();
        }
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the Setting details";
          }
        } else {
          errorMessage = "Unknown error while loading the Setting details";
        }
        toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
      });
  };
  const getVertaforeSettings = () => {
    const token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/vertaforesettings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        if (response.data.agencyName == "" || response.data.agencyName == null || response.data.userName == "" || response.data.userName == null) {
          localStorage.setItem("VertaforeSetting", "true");
        } else {
          localStorage.setItem("VertaforeSetting", "false");
        }
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the vertafore settings";
          }
        }
        toast.current.show({ severity: "error", detail: { errorMessage }, life: 3000 });
      });
  };
  const onCheckboxChange = (e: any, index: number) => {
    const updatedPremiumSettings = premiumSettings.map((item: any, i: any) => {
      if (i === index) {
        return { ...item, isChecked: !item.isChecked };
      }
      return item;
    });
    setPremiumSettings(updatedPremiumSettings);
    onSaveSettings(updatedPremiumSettings);
  };

  return (
    <div className="row mx-auto ">
      <Toast ref={toast} />

      <h4 className="pf-setting-title">Premium Finance Companies</h4>
      <div className={premiumLoading ? "col-12 mt-2 div-blur-loading" : "col-12 mt-2"}>
        <>
          {premiumSettings.map((i: any, index: number) => {
            return (
              <div className="row finance-company-name" key={index + 1}>
                <div className="col-8">
                  <span className="">{i.name}</span>
                </div>
                <div className="col-4  text-end">
                  <span className="">
                    <Toggle
                      id="useExisting"
                      // className=""
                      isChecked={i.isChecked}
                      onChange={(e: any) => onCheckboxChange(e, index)}
                    />{" "}
                  </span>
                </div>
              </div>
            );
          })}
        </>
      </div>
    </div>
  );
}
