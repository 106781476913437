import { useState, useEffect, useRef } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import DownloadIcon from "@atlaskit/icon/glyph/download";
import CommonValues, { Logout } from "../../common/utils";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import { Toast } from "primereact/toast";
import axios from "axios";
import { saveAs } from "file-saver";
import AtkButton from "../../AtlaskitControls/AtkButton";
import StarIcon from "@atlaskit/icon/glyph/star";
import AtkButtonIconBefore from "../../AtlaskitControls/AtkButtonIconBefore";
import ChevronRightLargeIcon from "@atlaskit/icon/glyph/chevron-right-large";
import ShareIcon from "@atlaskit/icon/glyph/share";
import { OverlayPanel } from "primereact/overlaypanel";
import moment from "moment";
import EditIcon from '@atlaskit/icon/glyph/edit';

function ResponseButton(props: any) {
  const [downloadloading, setDownloadLoading] = useState(false);
  const [saveQuoteloading, setSaveQuoteLoading] = useState(false);
  const navigate = useNavigate();
  const [isSaved, setIsSaved] = useState(true);
  const [url, setUrl] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [capitalBtnText, setCapitalBtnText] = useState(false);
  const [fIFPFfBtnText, setFIFPFfBtnText] = useState(false);
  const [loginLoading, setLoginLoading] = useState(false);
  const [capitalGotoPortalLoading, setCapitalGotoPortalLoading] = useState(false);
  const [gotoBtnText, setGotoBtnText] = useState(false);
  const [amsLoading, setAmsLoading] = useState(false);
  const [clientId, setClientId] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [providerName, setProviderName] = useState("");
  const [nCPolicyId, setNCPolicyId] = useState("");
  const [nCInsuredId, setNCInsuredId] = useState("");
  const [nCPolicyNumber, setNCPolicyNumber] = useState("");
  const [quoteNumber, setQuoteNumber] = useState("");
  const [peoplesBtnText, setPeoplesBtnText] = useState(false);
  const [sentToAMS, setSentToAMS] = useState(false);
  const [showHideSaveBtn, setShowHideSaveBtn] = useState(true);
  const toast: any = useRef("");
  const [ipfsLoginLoading, setIpfsLoginLoading] = useState(false);
  const [uspfBtnText, setUSPFfBtnText] = useState(false);
  const [usPfLoading, setUsPfLoading] = useState(false);
  const [fIFLoading, setFIFLoading] = useState(false);
  const [emailDescription, setEmailDescription] = useState("");
  const [sendEmailLoading, setSendEmailLoading] = useState(false);
  const emailRef = useRef<OverlayPanel>(null);

  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    setEmailDescription("Hello, I used the CPF quote and got the quote but was not able to download the PFA. Could you please help me out with this?")
    if (props.rowData) {
      setTransactionId(props.rowData.transactionId || "");
      setSentToAMS(props.rowData.sentToAMS || "");
      setUrl(props.rowData.url || "");
      setClientId(props.rowData.ClientId || "");
      setResponseMessage(props.rowData.message || "");

      if (props.rowData.targetPartner) {
        setProviderName(props.rowData.targetPartner);
      }

      if (props.rowData.provider == null || props.rowData.provider == "") {
        if (props.rowData.targetPartner) {
          setProviderName(props.rowData.targetPartner);
        }
      }

      setNCPolicyId(props.rowData.policyId || "");
      setNCInsuredId(props.rowData.insuredId || "");
      setNCPolicyNumber(props.rowData.ncPolicyNumber || "");

      if (props.rowData.ncPolicyNumber == null || props.rowData.ncPolicyNumber == "") {
        setNCPolicyNumber(props.rowData.policyNumber || "");
      }
      setQuoteNumber(props.rowData.quoteNumber || "");
      setIsSaved(props.rowData.isSaved);

      if (props.rowData.targetPartner) {
        if (props.rowData.targetPartner == "gotoPremiumFinance") {
          getGotoPfCredentialsStatus();
        }
        if (props.rowData.targetPartner == "Capital Premium Financing") {
          getCapitalPFCredentialStatus();
        }
        if (props.rowData.targetPartner == "Peoples Premium Finance") {
          getPeoplesPFCredentialStatus();
        }
        if (props.rowData.targetPartner == "First Insurance Funding") {
          getFIFPFCredentialStatus();
        }
        if (props.rowData.targetPartner == "US Premium Finance") {
          getUSPFCredentialStatus();
        }
      }
      if (props.rowData.isDocumentAvailable == false || props.rowData.responseMessage == "Insurance Company match not found") {
        setResponseMessage("Insurance Company match not found");
      }
    }
  }, [props.rowData]);

  const getButtonLabel = (targetName: any) => {
    switch (targetName) {
      case "Capital Premium Financing":
        //return capitalBtnText ? "Go To Portal" : "Contact";
        return "Contact";
      case "gotoPremiumFinance":
        return gotoBtnText ? "Go To Portal" : "Contact";
      case "Peoples Premium Finance":
        return peoplesBtnText ? "Go To Portal" : "Contact";
      case "US Premium Finance":
        return uspfBtnText ? "Go To Portal" : "Contact US PF";
      case "First Insurance Funding":
        return fIFPFfBtnText ? "Go To Portal" : "Contact";
      default:
        return "Continue";
    }
  };

  const buttonLabel = props.rowData?.targetPartner ? getButtonLabel(props.rowData.targetPartner) : "";

  const saveQuote = () => {
    setSaveQuoteLoading(true);
    setShowHideSaveBtn(true);
    var token = CommonValues.GetToken();
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/premiumfinance/savequote?transactionId=${transactionId}&isSaved=${!isSaved}`,

      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setShowHideSaveBtn(true);
        setIsSaved(!isSaved);
        if (!isSaved) {
          toast.current.show({ severity: "success", detail: "Quote saved successfully.", life: 3000 });
        } else {
          toast.current.show({ severity: "success", detail: "Quote unsaved successfully.", life: 3000 });
        }
        props.onSaveStatusUpdate(props.rowData.id, !isSaved);
        props.getPremiumFinanceSavedHistory();
        setSaveQuoteLoading(false);
      })
      .catch((error: any) => {
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while updating the Premium finance quote details by transaction id",
              life: 3000,
            });
          }
        }
        setSaveQuoteLoading(false);
      });
  };

  const onDownloadClick = () => {
    var token = CommonValues.GetToken();
    if (props.rowData.transactionId != "" && props.rowData.transactionId != null) {
      setDownloadLoading(true);
      axios({
        url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/agreement/${props.rowData.transactionId}`,
        method: "GET",
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response: any) => {
          saveAs(response.data, "" + props.rowData.targetPartner + "_" + props.rowData.policyNumber + "");
          setDownloadLoading(false);
        })
        .catch((error) => {
          if (error.response != null) {
            if (error.response.status == 401) {
              navigate("/");
              CommonValues.Logout(navigate);
            } else {
              toast.current.show({
                severity: "error",
                detail: "Unknown error while downloading the Premium finance Agreement",
                life: 3000,
              });
            }
          }
          setDownloadLoading(false);
        });
    }
  };
  const onIPFSContinueButtonClicked = () => {
    setIpfsLoginLoading(true);
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_IPFS_Url}/api/premiumfinance/ipfslogin`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setIpfsLoginLoading(false);
        window.open(`https://${response.data.item}`, "_blank");
      })
      .catch((error: any) => {
        setIpfsLoginLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else if (error.response.status == 404) {
            errorMessage = "Credentials not found. Please add your IPFS creds";
            toast.current.show({
              severity: "error",
              detail: { errorMessage },
              life: 3000,
            });
          } else {
            errorMessage = "Unknown error while continue to IPFS";
            toast.current.show({
              severity: "error",
              detail: { errorMessage },
              life: 3000,
            });
          }
        }
      });
  };

  const onContinueButtonClick = () => {
    if (providerName == "gotoPremiumFinance") {
      getGotopfDetailsForLogin();
    } else if (providerName == "Capital Premium Financing") {
      // getCapitalpfDetailsForGotoPortal();
      window.open("https://www.capitalpremium.net/contact", "_blank");
    } else if (providerName == "Peoples Premium Finance") {
      getPeoplespfDetailsForLogin();
    } else if (providerName == "IPFS") {
      onIPFSContinueButtonClicked();
    } else if (providerName == "US Premium Finance") {
      getCapitalpfDetailsForGotoPortal();
    } else if (providerName == "First Insurance Funding") {
      getFIFpfDetailsForLogin();
    } else if (url != "" && url != null) {
      window.open(url, "_blank");
    }
  };
  const getFIFpfDetailsForLogin = () => {
    setLoginLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/firstinsurancecgotoportal?transactionId=${props.transactionId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then((response: any) => {
        setLoginLoading(false);
        window.open(`${response.data}`, "_blank");
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the firstinsurancecgotoportal Setting details";
            toast.current.show({
              severity: "error",
              detail: { errorMessage },
              life: 3000,
            });
          }
        } else {
          errorMessage = "Unknown error while loading the firstinsurancecgotoportal Setting details";
          toast.current.show({
            severity: "error",
            detail: { errorMessage },
            life: 3000,
          });
        }
        setLoginLoading(false);
      });
  };
  const getGotopfDetailsForLogin = () => {
    setCapitalGotoPortalLoading(true);

    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/gotoportal?transactionId=${props.rowData.transactionId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then((response: any) => {
        setCapitalGotoPortalLoading(false);
        window.open(`${response.data}`, "_blank");
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the gotoPremiumFinance Setting details";
            toast.current.show({
              severity: "error",
              detail: { errorMessage },
              life: 3000,
            });
          }
        } else {
          errorMessage = "Unknown error while loading the gotoPremiumFinance Setting details";
          toast.current.show({
            severity: "error",
            detail: { errorMessage },
            life: 3000,
          });
        }
        setCapitalGotoPortalLoading(false);
      });
  };

  const getPeoplespfDetailsForLogin = () => {
    setCapitalGotoPortalLoading(true);

    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/peoplesportal?transactionId=${props.rowData.transactionId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then((response: any) => {
        setCapitalGotoPortalLoading(false);

        window.open(`${response.data}`, "_blank");
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({
              severity: "Unknown error while loading the Peoples Premium Finance Setting details",
              detail: { errorMessage },
              life: 3000,
            });
          }
        } else {
          toast.current.show({
            severity: "Unknown error while loading the Peoples Premium Finance Setting details",
            detail: { errorMessage },
            life: 3000,
          });
        }
        setCapitalGotoPortalLoading(false);
      });
  };

  const getCapitalpfDetailsForGotoPortal = () => {
    setCapitalGotoPortalLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/capitalgotoportal?transactionId=${props.rowData.transactionId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        window.open(response.data, "_blank");
        setCapitalGotoPortalLoading(false);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the capital premium financing  details";
            toast.current.show({
              severity: "error",
              detail: { errorMessage },
              life: 3000,
            });
          }
        } else {
          errorMessage = "Unknown error while loading the capital premium financing  details";
          toast.current.show({
            severity: "error",
            detail: { errorMessage },
            life: 3000,
          });
        }
        setCapitalGotoPortalLoading(false);
      });
  };

  const getGotoPfCredentialsStatus = () => {
    setLoginLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/gotopfcredentialstatus?transactionId=${props.rowData.transactionId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setLoginLoading(false);
        if (response.data) {
          setGotoBtnText(true);
        } else {
          setGotoBtnText(false);
        }
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the gotoPremiumFinance credential status ";
            toast.current.show({
              severity: "error",
              detail: { errorMessage },
              life: 3000,
            });
          }
        } else {
          errorMessage = "Unknown error while loading the gotoPremiumFinance credential status";
          toast.current.show({
            severity: "error",
            detail: { errorMessage },
            life: 3000,
          });
        }
        setLoginLoading(false);
      });
  };
  const getFIFPFCredentialStatus = () => {
    setFIFLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/firstinsurancecreds`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        if (response.status == 204) {
          setFIFPFfBtnText(false);
        } else {
          setFIFPFfBtnText(true);
        }
        setFIFLoading(false);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the First Insurance Funding Setting details";
            toast.current.show({
              severity: "error",
              detail: { errorMessage },
              life: 3000,
            });
          }
        } else {
          errorMessage = "Unknown error while loading the First Insurance Funding Setting details";
          toast.current.show({
            severity: "error",
            detail: { errorMessage },
            life: 3000,
          });
        }
        setFIFLoading(false);
      });
  };
  const getUSPFCredentialStatus = () => {
    setUsPfLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/uspfcreds`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        if (response.status == 204) {
          setUSPFfBtnText(false);
        } else {
          setUSPFfBtnText(true);
        }
        setUsPfLoading(false);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the USPF Setting details";
            toast.current.show({
              severity: "error",
              detail: { errorMessage },
              life: 3000,
            });
          }
        } else {
          errorMessage = "Unknown error while loading the USPF Setting details";
          toast.current.show({
            severity: "error",
            detail: { errorMessage },
            life: 3000,
          });
        }
        setUsPfLoading(false);
      });
  };
  const onSendToAMSClicked = () => {
    var token = CommonValues.GetToken();
    if (transactionId != "" && transactionId != null) {
      setAmsLoading(true);
      axios({
        url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/agreement/${transactionId}`,
        method: "GET",
        responseType: "blob", // important
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response: any) => {
          const file = new Blob([response.data], { type: "application/pdf" });
          const fileReader = new FileReader();

          fileReader.onload = function (evt) {
            var binary = "";
            var bytes = new Uint8Array(fileReader.result as ArrayBuffer);
            var len = bytes.byteLength;
            for (var i = 0; i < len; i++) {
              binary += String.fromCharCode(bytes[i]);
            }

            if (props.rowData.tenantId == process.env.REACT_APP_NowCerts_Tenant_Id) {
              sendToNowCertsAMSAPI(btoa(binary));
            }
            if (props.rowData.tenantId == process.env.REACT_APP_Hawksoft_Tenant_Id) {
              sendToAMSAPI(btoa(binary));
            }
            if (props.rowData.tenantId == process.env.REACT_APP_Vertafore_Tenant_Id) {
              setAmsLoading(false);
            }
          };
          fileReader.readAsArrayBuffer(file);
        })
        .catch((error) => {
          let errorMessage = "";
          if (error.response != null) {
            if (error.response.status == 401) {
              navigate("/");
              CommonValues.Logout(navigate);
            } else {
              errorMessage = "Unknown error while saving file to AMS ";
              toast.current.show({
                severity: "error",
                detail: { errorMessage },
                life: 3000,
              });
            }
          }
          setAmsLoading(false);
        });
    }
  };
  const sendToAMSAPI = (base64String: any) => {
    var data = JSON.stringify({
      TransactionId: transactionId,
      PolicyIndex: props.rowData.selectedPolicyIndex.toString(),
      ClientId: props.rowData.clientId.toString(),
      FileData: base64String,
      LogDescription: {
        Description: "File",
        LogDescription: "InsuranceGIG Premium Finance  Import",
        LogBody: `Here’s the document from Premium Finance
                  PF Provider   : ${providerName}
                  Quote Number  : ${quoteNumber}
                  Policy Number : ${nCPolicyNumber}
                  Total Premium : ${props.rowData.totalPremium}`,
      },
    });

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_HAWKSOFTAPI}/api/Hawksoft/log`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        updateSentToAMSStatus();
        setAmsLoading(false);
        toast.current.show({ severity: "success", detail: "File saved Successfully!", life: 3000 });
        setSentToAMS(true);
        props.onSendtoAmsUpdate(props.rowData.id, true);
      })

      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while saving file to AMS ";
            toast.current.show({ severity: "error", detail: "Unknown error while saving file to AMS", life: 3000 });
          }
        }
        setAmsLoading(false);
      });
  };

  const sendToNowCertsAMSAPI = (base64String: any) => {
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";

    var data = JSON.stringify({
      TransactionId: transactionId,
      FileName: nCPolicyNumber + "_" + providerName + "_" + quoteNumber,
      NCPolicyId: nCPolicyId,
      NCInsuredId: nCInsuredId,
      FileData: base64String,
      FolderName: nCPolicyNumber + "_" + providerName + "_" + quoteNumber,
      NowCertsToken: nowCertsToken,
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/policydocs`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("igtoken")}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        updateSentToAMSStatus();
        setAmsLoading(false);
        toast.current.show({ severity: "success", detail: "File saved Successfully!", life: 3000 });
        setSentToAMS(true);
        props.onSendtoAmsUpdate(props.rowData.id, true);
      })

      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while saving file to AMS ";
            toast.current.show({ severity: "error", detail: "Unknown error while saving file to AMS", life: 3000 });
          }
        }
        setAmsLoading(false);
      });
  };
  const getCapitalPFCredentialStatus = () => {
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/capitalpfcredentialstatus?transactionId=${props.rowData.transactionId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then((response: any) => {
        if (response.data) {
          setCapitalBtnText(true);
        } else {
          setCapitalBtnText(false);
        }
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the capital premium financing credential status ";
            toast.current.show({
              severity: "error",
              detail: { errorMessage },
              life: 3000,
            });
          }
        } else {
          errorMessage = "Unknown error while loading the capital premium financing credential status";
          toast.current.show({
            severity: "error",
            detail: { errorMessage },
            life: 3000,
          });
        }
      });
  };
  const getPeoplesPFCredentialStatus = () => {
    setLoginLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/peoplespfcredentialstatus`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setLoginLoading(false);
        if (response.data) {
          setPeoplesBtnText(true);
        } else {
          setPeoplesBtnText(false);
        }
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the Peoples Premium Finance credential status ";
            toast.current.show({
              severity: "error",
              detail: { errorMessage },
              life: 3000,
            });
          }
        } else {
          errorMessage = "Unknown error while loading the Peoples Premium Finance credential status";
          toast.current.show({
            severity: "error",
            detail: { errorMessage },
            life: 3000,
          });
        }
        setLoginLoading(false);
      });
  };

  const updateSentToAMSStatus = () => {
    var token = CommonValues.GetToken();

    var config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/PremiumFinance/amsimported?transactionId=${transactionId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => { })

      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while updating ams sent status ";
            toast.current.show({
              severity: "error",
              detail: { errorMessage },
              life: 3000,
            });
          }
        }
      });
  };
  const onContinueIPFSClicked = () => {
    window.open(props.rowData.documentURL, "_blank");
  };
  const handleSkip = () => {
    if (emailRef.current) {
      emailRef.current.hide();
    }
  };
  const onSendEmailClick = () => {
    setSendEmailLoading(true);
    var token = CommonValues.GetToken();
    var data = JSON.stringify({
      "agencyName": props.agencyname,
      "agentemail": CommonValues.GetWorkemail(),
      "quoteNumber": props.rowData.quoteNumber,
      "quoteDate": props.fromHistory==true? moment(props.rowData.quoteDate).format("YYYY-MM-DD"): moment(props.rowData.premiumresponse.quoteDate).format("YYYY-MM-DD"),
      "totalPremium": props.fromHistory==true? props.rowData.totalPremium.toString():props.rowData.premiumresponse.totalPremium.toString(),
      "description": emailDescription
    });

    var config = {
      method: 'post',
      url: `${process.env.REACT_APP_DataFabric_Url}/api/premiumfinance/sendcpfemail`,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        toast.current.show({
          severity: "success",
          detail: "Email has been sent successfully...!!!",
          life: 3000,
        });
        handleSkip();
        setSendEmailLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while updating ams sent status ";
            toast.current.show({
              severity: "error",
              detail: { errorMessage },
              life: 3000,
            });
          }
        }
        setSendEmailLoading(false);
      });

  };
  const onEmailDescriptionChanged = (e: any) => {
    setEmailDescription(e.target.value);
  }
  const onEditButtonClick = (savedQuoteId: any) => {
    navigate("/premiumfinance", { state: { savedQuoteId } });
  }
  return (
    <>
      <div className="row w-100 mx-auto">
        {props.rowData.targetPartner === "IPFS" && props.rowData.documentURL != "" && props.rowData.documentURL != null ? (
          <>
            <div className="col-lg-12  col-md-3 col-6 d-flex align-items-center justify-content-center  text-center btn-div">
              <AtkButton label="Continue" onClick={onContinueIPFSClicked} className="pf-primary-btn w-auto me-1   my-1 " />
            </div>
          </>
        ) : props.rowData.quoteNumber == "0" || props.rowData.quoteNumber == "" || props.rowData.quoteNumber == null ? (
          <span className=" text-12 quote-not-span p-2 m-1 w-100">Quote not available.</span>
        ) : (
          <>
            {props.rowData.targetPartner === "IPFS" ? null : (
              <>
                <Toast ref={toast} />
                <div className="col-lg-12  col-md-3 col-6 d-flex align-items-center justify-content-center   text-center btn-div">
                  {saveQuoteloading ? (
                    <>
                      <AtkLoader />
                    </>
                  ) : (
                    <>
                      {showHideSaveBtn ? (
                        <AtkButtonIconBefore
                          label={isSaved ? "Unsave " : "Save "}
                          onClick={saveQuote}
                          iconBefore={<StarIcon label="" />}
                          className="pf-primary-btn w-auto me-1   my-1 "
                        />
                      ) : null}
                    </>
                  )}
                </div>
                <div className="col-lg-12  col-md-3 col-6 d-flex align-items-center justify-content-center   text-center btn-div">
                  {capitalGotoPortalLoading ? (
                    <AtkLoader />
                  ) : (
                    <AtkButtonIconBefore
                      label={buttonLabel}
                      onClick={onContinueButtonClick}
                      iconBefore={<ChevronRightLargeIcon label="" />}
                      className="pf-primary-btn w-auto me-1 my-1"
                    />
                  )}
                </div>
                {responseMessage == "Insurance Company match not found" ? ( 
                  <div className="col-lg-11  col-md-6 col-12 d-flex align-items-center justify-content-center   text-center btn-div">
                    {" "}                    
                    <span className="text-14 mb-3">
                      Please Call 1-800-767-0705 for Additional Help, or you can email
                      <span className="show-cursor email-link" onClick={(e: any) => emailRef.current?.toggle(e)}>
                        {" "}
                        mainquote@capitalpremium.net
                      </span>
                      .
                    </span>{" "}
                    <OverlayPanel ref={emailRef} className="email-panel ">
                      <div className="row mx-auto text-12" >
                        <div className="col-12 p-2" style={{ background: "#d4d4d4" }}>
                          <div className="row">
                            <div className="col-6">
                              <h6>Send Email</h6>
                            </div>
                            <div className="col-6 text-end">
                              <h6 className="fw-bold show-cursor" onClick={handleSkip} title="Close">X</h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 p-2 border-bottom">
                          <span className="fw-bold">To: </span><span>mainquote@capitalpremium.net</span>
                        </div>
                        <div className="col-12 p-2 border-bottom">
                          <span className="fw-bold">Subject: </span><span> {props.agencyname} - {props.rowData.quoteNumber}</span>
                        </div>
                        <div className="col-12 p-2 ">
                          <textarea
                            name="emaildescription"
                            onChange={onEmailDescriptionChanged}
                            value={emailDescription}
                            className="form-control email-text mb-2 "
                            rows={6}
                            placeholder="How can we help you"
                          />
                        </div>
                        <div className="col-12 text-end my-3">
                          {sendEmailLoading ?
                            <span className="me-2" >
                              <AtkLoader />
                            </span> :
                            <AtkButton label="Send" onClick={onSendEmailClick} className="pf-secondary-btn w-auto " />
                          }
                        </div>
                      </div>                     
                    </OverlayPanel>
                  </div>
                ) : (
                  <>
                    <div className="col-lg-12  col-md-3 col-6 d-flex align-items-center justify-content-center   text-center btn-div">
                      {downloadloading ? (
                        <AtkLoader />
                      ) : (
                        <AtkButtonIconBefore
                          label="Download"
                          onClick={onDownloadClick}
                          iconBefore={<DownloadIcon label="" />}
                          className="pf-primary-btn w-auto me-1   my-1 "
                        />
                      )}
                    </div>
                  </>
                )}
               {responseMessage == "Insurance Company match not found" ? null : ( 
                  <>
                    <div className="col-lg-12  col-md-3 col-6 d-flex align-items-center justify-content-center   text-center btn-div">
                      {amsLoading ? (
                        <AtkLoader />
                      ) : (
                        <>
                          {sentToAMS ? (
                            <>
                              <span className=" text-12">
                                <i className="fa fa-check sent-to-ams-icon" />
                                Saved to AMS
                              </span>
                            </>
                          ) : (
                            <>
                              {props.rowData.tenantId == process.env.REACT_APP_Vertafore_Tenant_Id ? null : (
                                <>
                                  {localStorage.getItem("isNewPolicy") == "true" ||
                                    props.rowData.isIndicative == "true" ||
                                    localStorage.getItem("IsIndicative") == "true" ? null : (
                                    <AtkButtonIconBefore
                                      label="Save to AMS Files"
                                      onClick={onSendToAMSClicked}
                                      iconBefore={<ShareIcon label="" />}
                                      className="pf-primary-btn w-auto me-1 my-1 "
                                    />
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </>
                )}
                {isSaved && window.location.pathname ==="/pfsavedquotes" && props.rowData.isIndicative != "true"?
                  <div className="col-lg-12 col-md-3 col-6 d-flex align-items-center justify-content-center text-center btn-div">
                    <AtkButtonIconBefore
                      label="Edit"
                      onClick={() => onEditButtonClick(props.rowData.transactionId)}
                      iconBefore={<EditIcon label="" />}
                      className="pf-primary-btn w-auto me-1 my-1"
                    />
                  </div>
                  :
                  null}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
export default ResponseButton;