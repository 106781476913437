import Header from "../../common/Header";
import CommonValues from "../../common/utils";
import ValchoiceMiddleHeader from "./ValchoiceMiddleHeader";
import ValchoiceSidebar from "./ValchoiceSidebar";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import AtkLoader from "../../AtlaskitControls/AtkLoader";
import PreviewIcon from "../../assets/images/ViewDetailsIcon.png";
import { Dialog } from "primereact/dialog";
import feedbackImg from "../../assets/images/feedback.svg";
import { OverlayPanel } from "primereact/overlaypanel";
import AtkButton from "../../AtlaskitControls/AtkButton";
import Footer from "../../common/Footer";
import moment from "moment";

export default function ValchoiceHistory() {
  const toast: any = useRef("");
  const navigate = useNavigate();
  const [historyloading, setHistoryLoading] = useState(true);
  const [historydata, setHistoryData] = useState<any>([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [feedBackData, setFeedBackData] = useState<any>([]);
  const [feedBackError, setFeedBackError] = useState("");
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const feedbackRef = useRef<OverlayPanel>(null);

  useEffect(() => {
    getValchoiceHistory();
  }, []);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  useEffect(() => {
    if (showPopUp) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
    return () => document.body.classList.remove("no-scroll");
  }, [showPopUp]);
  const getValchoiceHistory = () => {
    setHistoryLoading(true);
    const token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/valchoice/valchoiceTransactions`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setHistoryData(response.data);
        setHistoryLoading(false);
      })
      .catch((error: any) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while getting Valchoice history", life: 3000 });
          }
        }
        setHistoryLoading(false);
      });
  };
  const actionTemplate = (rowData: any) => {
    return rowData.fileName != "" && rowData.fileName != null ? (
      <>
        <img src={PreviewIcon} height={20} onClick={() => onPreviewClick(rowData)} title="Preview" className="show-cursor" />
      </>
    ) : null;
  };
  const fileTemplate = (rowData: any) => {
    return rowData.fileName != "" && rowData.fileName != null ? <span>{rowData.fileName}</span> : null;
  };
  const onPreviewClick = (rowData: any) => {
    const matchedItem = historydata.find((item: any) => item.transactionId === rowData.transactionId);
    if (matchedItem) {
      setPdfUrl(matchedItem.fileName);
    }
    setShowPopUp(true);
  };

  const onHidePopupClicked = () => {
    setShowPopUp(false);
    setPdfUrl("");
  };
  const feedbackTemplate = (rowData: any) => {
    return <img src={feedbackImg} className=" show-cursor" onClick={(e) => onShowFeedbackPopup(e, rowData)} title="Feedback" />;
  };
  const onFeedbackChange = (newFeedback: string) => {
    const feedback = { ...feedBackData, feedback: newFeedback };
    setFeedBackData(feedback);
    if (newFeedback.length == 0 || newFeedback.trim() === "") {
      setFeedBackError("Please enter your feedback");
    } else if (newFeedback.length > 0) {
      setFeedBackError("");
    }
  };
  const onThumbsUpClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === true) {
      liked = null;
    } else {
      liked = true;
    }
    rowData = { ...rowData, isLiked: liked };
    setFeedBackData(rowData);
    setHistoryData((prevResponses: any) =>
      prevResponses.map((response: any) => (response.id === rowData.id ? { ...response, isLiked: liked } : response))
    );
    onSaveRatings(rowData);
  };
  const onThumbDownClick = (rowData: any) => {
    let liked: any;
    if (rowData.isLiked === false) {
      liked = null;
    } else {
      liked = false;
    }
    rowData = { ...rowData, isLiked: liked };
    setFeedBackData(rowData);
    onSaveRatings(rowData);
    setHistoryData((prevResponses: any) =>
      prevResponses.map((response: any) => (response.id === rowData.id ? { ...response, isLiked: liked } : response))
    );
  };
  const onSubmitFeedbackClick = (rowData: any) => {
    if (rowData.feedback == null || rowData.feedback == "") {
      setFeedBackError("Please enter your feedback");
    } else {
      setFeedBackError("");
      setFeedbackLoading(true);
      onSaveFeedback(rowData);
    }
  };
  const onSaveRatings = (rowData: any) => {
    const token = CommonValues.GetToken();
    let data = {
      Id: rowData.id,
      IsLiked: rowData.isLiked,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/valchoice/valchoiceratings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", detail: "Rating submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: rowData.isLiked };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while submitting ratings", life: 3000 });
          }
        }
      });
  };
  const onSaveFeedback = (rowData: any) => {
    const token = CommonValues.GetToken();
    const feedbackVal = rowData.feedback == null ? "" : rowData.feedback;
    let data = {
      Id: rowData.id,
      Feedback: feedbackVal,
    };

    let config = {
      method: "post",
      url: `${process.env.REACT_APP_DataFabric_Url}/api/valchoice/valchoicefeedback`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        toast.current.show({ severity: "success", detail: "Feedback submitted successfully", life: 3000 });
        const feedback = { feedback: "", isLiked: null };
        setFeedbackLoading(false);
        setFeedBackData(feedback);
        onHideFeedbackPopup();
      })
      .catch((error: any) => {
        console.log(error);
        setFeedbackLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while submitting feedback", life: 3000 });
          }
        }
      });
  };
  const onShowFeedbackPopup = (e: any, rowData: any) => {
    setFeedBackError("");
    const feedback = { ...rowData, feedback: "" };
    setFeedBackData(feedback);
    feedbackRef.current?.toggle(e);
  };
  const onHideFeedbackPopup = () => {
    if (feedbackRef.current) {
      feedbackRef.current.hide();
      setFeedBackData([]);
    }
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="valchoice-history-main-page">
        <Header pagename="valchoice-history" />
        <div className="content-wrapper ">
          <div className="row  wrapper-main-row mx-auto wrapper-below-div">
            <div className="col-12">
              <ValchoiceMiddleHeader />
            </div>
            <div className="row main-content-row mx-auto">
              <div className="col-lg-2 col-md-3 sidebar-col display-for-desktop">
                <ValchoiceSidebar pagename="valchoice-history" />
              </div>

              <div className="col-lg-10 col-md-12 col-sm-12 main-content-col ">
                <div className="row  mt-2 d-flex  align-items-end text-12 position-relative">
                  <div className="col-12  history-table mt-3">
                    <Toast ref={toast} />
                    {historyloading ? (
                      <div className="d-flex align-ietms-center justify-content-center overflow-hidden">
                        <AtkLoader />
                      </div>
                    ) : (
                      <DataTable value={historydata} paginator paginatorTemplate="PrevPageLink PageLinks NextPageLink" rows={15}>
                        <Column header="Insured Name" field="insuredName"></Column>
                        <Column header="Carrier Name" field="carrierName"></Column>
                        <Column
                          header="Submitted Date"
                          field="created"
                          body={(rowData) => {
                            const utcdate = moment.utc(rowData.created);
                            var localDate = moment(utcdate).local();
                            return <span className="">{localDate.format("MM-DD-YYYY HH:mm")} </span>;
                          }}
                          sortable
                        ></Column>
                        <Column header="File Name (URL is valid for 1 year)" body={fileTemplate} field="fileName"></Column>

                        <Column header="Action" body={actionTemplate}></Column>
                        <Column header="Feedback" body={feedbackTemplate}></Column>
                      </DataTable>
                    )}
                  </div>
                  <Dialog
                    header="Carrier Rate Service Rating Report"
                    visible={showPopUp}
                    style={{ width: "80vw", height: "90vh" }}
                    onHide={onHidePopupClicked}
                    draggable={false}
                  >
                    <div className="row">
                      <div className="col-12 ">
                        {/* <b>Carrier Rate Service Rating Report</b> */}
                        <iframe src={pdfUrl} style={{ overflow: "scroll", height: "75vh", width: "100%" }} className="mt-2"></iframe>
                      </div>
                    </div>
                  </Dialog>

                  <OverlayPanel ref={feedbackRef} className="commission-feedback-panel feedback-panel">
                    <div className="row mx-auto d-flex align-items-center mb-2">
                      <div className="col-12 feedback-header">
                        <h4>Feedback</h4>
                      </div>
                      <div className={"form-group col-12 mb-2 text-12"}>
                        <textarea
                          name="feedback"
                          onChange={(e) => onFeedbackChange(e.target.value)}
                          value={feedBackData.feedback}
                          className={`form-control comment-text ${feedBackError.length > 0 ? " validation-div " : ""}`}
                          rows={4}
                          placeholder="Enter your feedback here"
                        />
                        <span className="text-danger  text-12 mt-1">{feedBackError}</span>
                      </div>
                      <div className="col-4 d-flex justify-content-evenly">
                        <i
                          className={`show-cursor fa fa-thumbs-up commission-feedback-icon 
               ${feedBackData.isLiked == true ? " active" : " "}`}
                          aria-hidden="true"
                          onClick={(e) => onThumbsUpClick(feedBackData)}
                        ></i>
                        <i
                          className={`show-cursor fa fa-thumbs-down commission-feedback-icon 
                ${feedBackData.isLiked == false ? " active" : " "}`}
                          aria-hidden="true"
                          onClick={(e) => onThumbDownClick(feedBackData)}
                        ></i>
                      </div>
                      <div className="col-4 text-end p-2 pe-0  ">
                        <AtkButton label="Cancel" onClick={onHideFeedbackPopup} className="pf-secondary-btn w-auto" />
                      </div>
                      <div className="col-4 text-end p-2  ">
                        {/* {submissionId == feedBackData.submissionId && feedbackLoading ? ( */}
                        {feedbackLoading ? (
                          <div className="d-flex justify-content-center align-items-center">
                            <AtkLoader />
                          </div>
                        ) : (
                          <AtkButton label="Submit" onClick={() => onSubmitFeedbackClick(feedBackData)} className="pf-secondary-btn w-auto " />
                        )}
                      </div>
                    </div>
                  </OverlayPanel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
}
