
import React from "react";
import HistoryIcon from "../../assets/images/History_B.svg";
import { useNavigate } from "react-router-dom";
import NewIcon from "../../assets/images/newquote.png";
export default function SmartScanSidebar(props: any) {
  const navigate = useNavigate();

  const onSmartScanClick = () => {
    navigate("/smartscan");
  };
  const onSmartScanHistoryClick = () => {
    navigate("/smartscanhistory");
  };
  
  return (
    <div className="pt-4 pf-sidebar-block p-0">
      <>
        <span
          className={props.pagename == "smart-scan" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
          onClick={onSmartScanClick}
        >
          <img src={NewIcon} className={props.pagename == "smart-scan" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} />
          <span className="sidebar-menu-title">New Smart Scan</span>{" "}
        </span>

       
        <span
          className={props.pagename == "smart-scan-history" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
          onClick={onSmartScanHistoryClick}
        >
          <img
            src={HistoryIcon}
            className={props.pagename == "smart-scan-history" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "}
          />
          <span className="sidebar-menu-title">History</span>{" "}
        </span>
       
      </>
    </div>
  );
}

