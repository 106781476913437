import React, { useEffect, useRef, useState } from "react";
import AtkButton from "../AtlaskitControls/AtkButton";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import AtkMessage from "../AtlaskitControls/AtkMessage";
import AtkTextField from "../AtlaskitControls/AtkTextField";
import CommonValues from "../common/utils";
import axios from "axios";
import { Toast } from "primereact/toast";
import { Navigate, useNavigate } from "react-router-dom";
import WatchFilledIcon from "@atlaskit/icon/glyph/watch-filled";
import WatchIcon from "@atlaskit/icon/glyph/watch";

export default function CapitalPfSettings() {
  const [errorMessageUsername, setErrorMessageUsername] = useState("");
  const [errorMessagePassword, setErrorMessagePassword] = useState("");
  const [errorMessageSearchCode, setErrorMessageSearchCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [showIGCredsMsg, setShowIGCredsMsg] = useState(false);
  const [username, setUsername] = useState("");
  const [searchCode, setSearchCode] = useState("");
  const [capitalSetupPassword, setCapitalSetupPassword] = useState("");
  const [capitalLoading, setCapitalLoading] = useState(false);
  const [deleteCapitalLoading, setDeleteCapitalLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const toast: any = useRef("");
  const navigate = useNavigate();

  const onUsernameChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
  }
    setUsername(e.target.value);
    setSearchCode(e.target.value);
  };

  const onPasswordChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
  }
    setCapitalSetupPassword(e.target.value);
  };
  const onSearchCodeChange = (e: any) => {
    setSearchCode(e.target.value);
  };

  const onShowPassword = () => {
    if (passwordType == "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const onKeyUpdateCapitalClick = (e: any) => {
    if (e.keyCode == 13) {
      onUpdateCapitalClick();
    }
  };
  // const capitalCheckValidation = () => {
  //   let formIsValid = true;
  //   setErrorMessageUsername("");
  //   setErrorMessagePassword("");
  //   setErrorMessageSearchCode("");

  //   if (username == "" || username == null) {
  //     formIsValid = false;
  //     setErrorMessageUsername("Please enter user name");
  //   }
  //   if (capitalSetupPassword == "" || capitalSetupPassword == null) {
  //     formIsValid = false;
  //     setErrorMessagePassword("Please enter password");
  //   }

  //   return formIsValid;
  // };
  const getCapitalCreds = () => {
    setLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/capitalcreds`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        if (response.status == 204) {
          setUsername("");
          setSearchCode("");
          setCapitalSetupPassword("");
          setShowIGCredsMsg(true);
        } else {
          setShowIGCredsMsg(false);
          setUsername(response.data.username);
          setSearchCode(response.data.searchCode);
          if (response.data.username !== null && response.data.username !== "" && response.data.password === "") {
            setCapitalSetupPassword("*********");
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while loading the Capital Setting details" });
          }
        } else {
          toast.current.show({ severity: "error", detail: "Unknown error while loading the Capital Setting details" });
        }
        setLoading(false);
      });
  };

  const onUpdateCapitalClick = () => {
    // if (capitalCheckValidation()) {
      setCapitalLoading(true);
      let token = CommonValues.GetToken();

      var data = JSON.stringify({
        Username: username,
        Password: capitalSetupPassword,
        SearchCode: searchCode,
      });

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_UserService_Url}/api/user/capitalcreds`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((response: any) => {
          setCapitalLoading(false);
          toast.current.show({ severity: "success", detail: "Capital Settings updated successfully", life: 3000 });
          getCapitalCreds();
        })
        .catch((error: any) => {
          console.log(error);
          let errorMessage = "";
          if (error.response != null) {
            if (error.response.status == 401) {
              navigate("/");
              CommonValues.Logout(navigate);
            } else if (error.response.status == 409) {
              toast.current.show({
                severity: "error",

                detail: "It look likes you are not registered with Capital, Please register first",
              });
            } else {
              toast.current.show({ severity: "error", detail: "Unknown error while updating the Capital Settings" });
            }
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while updating the Capital Settings" });
          }
          setCapitalLoading(false);
        });
    // }
  };
  const onDeleteCapitalClick = () => {
    setDeleteCapitalLoading(true);
    let token = CommonValues.GetToken();

    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/capitalcreds`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setDeleteCapitalLoading(false);
        toast.current.show({ severity: "success", detail: "Capital Settings deleted successfully", life: 3000 });
        getCapitalCreds();
      })
      .catch((error: any) => {
        console.log(error);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while deleting the Capital Settings" });
          }
        } else {
          toast.current.show({ severity: "error", detail: "Unknown error while deleting the Capital Settings" });
        }
        setDeleteCapitalLoading(false);
      });
  };
  return (
    <div className="row  mx-auto mt-3 d-flex align-items-center setting-setup-width">
      <Toast ref={toast} />
      {loading ? (
        <div className="d-flex justify-content-center">
          <AtkLoader />
        </div>
      ) : (
        <>
          

          <div className="form-group col-4  mb-2">
            <label className="form-label">
              Username
              {/* <span className="text-danger">*</span> */}
            </label>
          </div>
          <div className={errorMessageUsername.length > 0 ? " col-8  mb-2 validation-div" : " col-8  mb-2"}>
            <AtkTextField
              className="input-field"
              type="text"
              id="username"
              name="username"
              onChange={onUsernameChange}
              value={username}
              placeholder="Enter user name"
              onKeyDown={onKeyUpdateCapitalClick}
            />
            {/* <span className="text-danger text-12">{errorMessageUsername}</span> */}
          </div>

          <div className="form-group col-4 ">
            <label className="form-label">
              Password
              {/* <span className="text-danger">*</span> */}
            </label>
          </div>
          <div className={errorMessagePassword.length > 0 ? " col-8   validation-div position-relative" : " col-8  mb-2 position-relative"}>
            <AtkTextField
              className="input-field"
              type={passwordType}
              id="headerKey"
              name="CapitalSetupPassword"
              onChange={onPasswordChange}
              value={capitalSetupPassword}
              placeholder="Enter password"
              onKeyDown={onKeyUpdateCapitalClick}
              autoComplete="new-password"
            />
            <span className="eye-icon" onClick={onShowPassword}>
              {passwordType === "password" ? <WatchFilledIcon label="Pause" /> : <WatchIcon label="Play" />}
            </span>
          </div>
          {/* {errorMessagePassword.length > 0 ? (
            <>
              <div className="col-4  mb-2"></div>
              <div className=" col-8  mb-2">
                <span className="text-danger text-12">{errorMessagePassword}</span>
              </div>
            </>
          ) : null} */}
          <div className="form-group col-4  mb-2">
            <label className="form-label">Search Code</label>
          </div>
          <div className={errorMessageSearchCode.length > 0 ? " col-8  mb-2 validation-div" : " col-8  mb-2"}>
            <AtkTextField
              className="input-field"
              type="text"
              id="ownerSearchCode"
              name="ownerSearchCode"
              onChange={onSearchCodeChange}
              value={searchCode}
              onKeyDown={onKeyUpdateCapitalClick}
              placeholder="Enter search code"

            />
            <span className="text-danger text-12">{errorMessageSearchCode}</span>
          </div>
        </>
      )}

      <>
        <div className="col-12  text-end mb-2 mt-2">
          {deleteCapitalLoading ? (
            <AtkLoader></AtkLoader>
          ) : (
            <AtkButton label="Reset" onClick={onDeleteCapitalClick} className="pf-secondary-btn w-auto me-2" />
          )}
          {capitalLoading ? <AtkLoader></AtkLoader> : <AtkButton label="Save" onClick={onUpdateCapitalClick} className="pf-primary-btn w-auto " />}
        </div>
        <div className="col-md-12 mb-2">
            {showIGCredsMsg ? <AtkMessage appearance="information" messageText="Using InsuranceGIG Credentials" /> : null}
          </div>
      </>
    </div>
  );
}