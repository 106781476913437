import React from 'react';
import HistoryIcon from "../assets/images/History_B.svg";
import { useNavigate } from "react-router-dom";
import NewIcon from "../assets/images/NewQuote_B 1.svg";

export default function RabbitSignSidebar(props: any) {
  const navigate = useNavigate();

  const onRabbitSignHistoryClick =()=>{
    navigate("/rabbitsignhistory")
  }

  const onNewConfigureRabbitSign=()=>{
    navigate("/rabbitsign")
  }
  return (
    <div className="pt-4 pf-sidebar-block p-0">
      <>
        <span
          className={props.pagename == "rabbitsign-page" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
        //  onClick={onNewConfigureRabbitSign}
        >
          <img src={NewIcon} className={props.pagename == "rabbitsign-page" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} />
          <span className="sidebar-menu-title">Configure E-Signature</span>{" "}
        </span>
        {/* <span
          className={props.pagename == "naics-classification-history" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
        // onClick={onRabbitSignHistoryClick}
        >
          <img
            src={HistoryIcon}
            className={props.pagename == "naics-classification-history" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "}
          />
          <span className="sidebar-menu-title">History</span>{" "}
        </span> */}
      </>
    </div>
  )
}
