import React from 'react'
import RabbitSignLogo from "../assets/images/RabbitSignLogo.png";

export default function RabbitSignMiddleHeader() {
  return (
    <div className="row middle-header-row">
      <div className="col-12">
        <div className="middle-header-col active ms-2">
          <img src={RabbitSignLogo} className="" width={30}/>
          <span className="middle-header-title">E-Signature</span>
        </div>
      </div>{" "}
    </div>
  )
}
