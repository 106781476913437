import React, { useEffect, useState } from "react";
import AtkTextField from "../../AtlaskitControls/AtkTextField";
import moment from "moment";
import CrossCircleIcon from "@atlaskit/icon/glyph/cross-circle";
import { Navigate, useNavigate } from "react-router-dom";
import CommonValues, { Logout } from "../../common/utils";
import { Skeleton } from "primereact/skeleton";

export default function SubpolicyDetails(props: any) {
  const navigate = useNavigate();
  useEffect(() => {
    const token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }

  }, []);

  return (
    <div className="row subpolicy-block ">
      {props.dataMappingLoading[props.index] == true ?
        <Skeleton
          height="400px" />
        :
        <>
          <div
            className={
              props.policyNumberError[props.index] != undefined &&
                props.policyNumberError[props.index].length > 0
                ? "form-group col-lg-4 col-md-4 col-sm-12 mb-2 validation-div"
                : "form-group col-lg-4 col-md-4 col-sm-12 mb-2"
            }
          >
            <label>Policy Number</label>
            <span className="text-danger ">*</span>

            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={props.policyNumber[props.index]}
              onChange={props.onPolicyNumberChange}
              placeholder="Enter policy number"
            />
            <span className="text-danger text-12">
              {props?.policyNumberError[props.index]}
            </span>
          </div>

          <div
            className={
              props.effectiveDateError[props.index] != undefined &&
                props.effectiveDateError[props.index].length > 0
                ? "form-group col-lg-4 col-md-4 col-sm-12 mb-2 validation-div"
                : "form-group col-lg-4 col-md-4 col-sm-12 mb-2"
            }
          >
            <label>Effective Date</label>
            <span className="text-danger ">*</span>

            <AtkTextField
              className="input-field "
              type="date"
              id="text"
              value={
                props.effectiveDate[props.index] != undefined &&
                  props.effectiveDate[props.index] != null
                  ? moment(props.effectiveDate[props.index]).format("YYYY-MM-DD")
                  : ""
              }
              onChange={props.onEffectiveDateChange}
              placeholder="Select effective date"
            />
            <span className="text-danger text-12">
              {props?.effectiveDateError[props.index]}
            </span>
          </div>

          <div
            className={
              props.currentPremiumError[props.index] != undefined &&
                props.currentPremiumError[props.index].length > 0
                ? "form-group col-lg-4 col-md-4 col-sm-12 mb-2 validation-div"
                : "form-group col-lg-4 col-md-4 col-sm-12 mb-2"
            }
          >
            <label>Current Premium </label>
            <span className="text-danger ">*</span>
            <div className="input-group ">
              <span className="input-group-text">$</span>
              <AtkTextField
                className="input-field form-control p-0"
                type="number"
                id="text"
                value={props?.currentPremiumsArray[props.index]}
                onChange={props.onPremiumChange}
                placeholder="Enter current premium"
              />
            </div>
            <span className="text-danger text-12">
              {props?.currentPremiumError[props.index]}
            </span>
          </div>

          <div
            className={
              props.coverageCodeError[props.index] != undefined &&
                props.coverageCodeError[props.index].length > 0
                ? "form-group col-lg-4 col-md-4 col-sm-12 mb-2 position-relative validation-div"
                : "form-group col-lg-4 col-md-4 col-sm-12 mb-2 position-relative"
            }
          >
            <label>Coverage Code</label>
            <span className="text-danger ">*</span>

            <AtkTextField
              className="input-field policydetails "
              type="text"
              id="text"
              value={props?.search.length > 0 ? props.search[props.index] : ""}
              onChange={props.onCoverageCodeChange}
              placeholder={
                props.inputValue != undefined && props.inputValue != null && props.inputValue != ""
                  ? props.inputValue
                  : "Search and select coverage codes..."
              }
            />
            {props?.search[props.index] != undefined &&
              props?.search[props.index] != null &&
              props?.search[props.index].length > 0 && (
                <>
                  {props.GotoPfCodeList.length > 0 && (
                    <div
                      id="hs-client-search"
                      className="canopy-clientselector-dropdown"
                    >
                      {props?.GotoPfCodeList.map((item: any) => (
                        <div
                          style={{
                            cursor: "pointer",
                            width: "100%",
                          }}
                          onClick={(e) => {
                            props.onCovergaeCodeselect(item, e);
                          }}
                        >
                          <a
                            href="#"
                            style={{
                              color: "black",
                              width: "100%",
                              fontSize: "12px",
                              lineHeight: "18px",
                            }}
                          >
                            {item.description}
                          </a>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              )}
            <span className="text-danger text-12">
              {props?.coverageCodeError[props.index]}
            </span>
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Taxes</label>
            <div className="input-group ">
              <span className="input-group-text">$</span>
              <AtkTextField
                className="input-field form-control p-0"
                type="number"
                id="text"
                value={props?.taxes[props.index]}
                onChange={props.onTaxesChange}
                placeholder="Enter taxes"
              />
            </div>
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Fees</label>
            <div className="input-group ">
              <span className="input-group-text">$</span>
              <AtkTextField
                className="input-field form-control p-0"
                type="number"
                id="text"
                value={props?.fees[props.index]}
                onChange={props.onFeesChange}
                placeholder="Enter fees"
              />
            </div>
          </div>
          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Minimum Earned %</label>
            <div className="input-group ">
              <span className="input-group-text">%</span>
              <AtkTextField
                className="input-field form-control p-0"
                type="number"
                id="text"
                value={props?.minimumEarned[props.index]}
                onChange={props.onMinimumEarnedChange}

              />
            </div>
          </div>

          <div
            className={
              props.carrierNameError[props.index] != undefined &&
                props.carrierNameError[props.index].length > 0
                ? "form-group col-lg-4 col-md-4 col-sm-12 mb-2 position-relative validation-div"
                : "form-group col-lg-4 col-md-4 col-sm-12 mb-2 position-relative"
            }
          >
            <label>Carrier Name</label>
            <span className="text-danger ">*</span>

            <AtkTextField
              className="input-field policydetails"
              type="text"
              id="text"
              value={
                props?.carrierSearch.length > 0
                  ? props.carrierSearch[props.index]
                  : ""
              }
              onChange={props?.onCarrierNameChange}
              placeholder={
                props.carrierValue[props.index] != undefined &&
                  props.carrierValue[props.index] != null &&
                  props.carrierValue[props.index].description != undefined &&
                  props.carrierValue[props.index].description != null
                  ? props.carrierValue[props.index].description
                  : "Search and select carrier name..."
              }
            />
            {props.carrierSearch[props.index] != undefined &&
              props.carrierSearch[props.index] != null &&
              props.carrierSearch.length > 0 && (
                <>
                  {props.carrierList.length > 0 ? (
                    <div
                      id="hs-client-search"
                      className="canopy-clientselector-dropdown"
                    >
                      {props?.carrierList.map((item: any) => (
                        <div
                          style={{
                            cursor: "pointer",
                            width: "100%",
                          }}
                          onClick={(e) => {
                            props.onCarrierselect(item, e);
                          }}
                        >
                          <a
                            href="#"
                            style={{
                              color: "black",
                              width: "100%",
                              fontSize: "12px",
                              lineHeight: "18px",
                            }}
                          >
                            {item.description}
                          </a>
                        </div>
                      ))}
                    </div>
                  ) :
                    <div
                      style={{
                        padding: "5px",
                        background: "#ddd",
                      }}
                      className="text-center show-cursor w-100"
                    >
                      <a className="carrier-option-link" onClick={props?.onSetCarrierNameRuntime}>
                        Use this carrier name
                      </a>
                    </div>
                  }
                </>
              )}
            <span className="text-danger text-12">
              {props?.carrierNameError[props.index]}
            </span>
          </div>
          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Carrier Address</label>
            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={props?.carrierAddress[props.index]}
              onChange={props?.onCarrierAdressChange}
              placeholder="Enter carrier address"
            />
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Carrier City</label>
            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={props?.carrierCity[props.index]}
              onChange={props.onCarrierCityChange}
              placeholder="Enter carrier city"
            />
            <span className="text-danger text-12">{props.carrierCityError}</span>
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Carrier State</label>
            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={props?.carrierState[props.index]}
              onChange={props.onCarrierStateChange}
              placeholder="Enter carrier state"
            />
            <span className="text-danger text-12">{props.carrierStateError}</span>
          </div>

          <div
            className={
              props.carrierZipCodeError[props.index] != undefined &&
                props.carrierZipCodeError[props.index].length > 0
                ? "form-group col-lg-4 col-md-4 col-sm-12 mb-2 validation-div"
                : "form-group col-lg-4 col-md-4 col-sm-12 mb-2"
            }
          >
            <label>Carrier Zip </label>
            <span className="text-danger ">*</span>

            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={props?.carrierZip[props.index]}
              onChange={props.onCarrierZipChange}
              placeholder="Enter carrier zip"
            />
            <span className="text-danger text-12">
              {props.carrierZipCodeError[props.index]}
            </span>
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>Carrier Country </label>

            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={props?.carrierCountry[props.index]}
              onChange={props.onCarrierCountryChange}
              placeholder="Enter carrier country"
            />
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2 position-relative">
            <label>General Agency Name</label>
            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={props?.gaName[props.index]}
              onChange={props.onGeneralAgencySearchChange}
              elemAfterInput={
                <span
                  onClick={props?.onClearGACLicked}
                  style={{ cursor: "pointer" }}
                >
                  {props?.generalAgencyName[props.index] != "" && props?.generalAgencyName[props.index] != null ? (
                    <CrossCircleIcon label="Clear ga" />
                  ) : null}
                </span>
              }
              placeholder="Enter general agency name"
            />
            {props.generalAgency.length > 0 ? (
              <div id="hs-client-search" className="canopy-clientselector-dropdown">
                {props?.generalAgency.map((item: any) => (
                  <div
                    style={{
                      cursor: "pointer",
                      width: "100%",
                    }}
                    onClick={(e) => {
                      props.onGeneralAgencySelect(item, e);
                    }}
                  >
                    <a
                      href="#"
                      style={{
                        color: "black",
                        width: "100%",
                        fontSize: "12px",
                        lineHeight: "18px",
                      }}
                    >
                      {item.description}
                    </a>
                  </div>
                ))}
              </div>
            ) : null}
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>General Agency Address</label>

            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={props?.generalAgencyAddress[props.index]}
              onChange={props?.onGeneralAgnecyAdressChange}
              placeholder="Enter general agency address"
            />
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>General Agency City</label>

            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={props?.generalAgencyCity[props.index]}
              onChange={props.onGeneralAgnecyCityChange}
              placeholder="Enter general agency city"
            />
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>General Agency State</label>

            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={props?.generalAgencyState[props.index]}
              onChange={props.onGeneralAgnecyStateChange}
              placeholder="Enter general agency state"
            />
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>General Agency Zip</label>

            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={props?.generalAgencyZip[props.index]}
              onChange={props.onGeneralAgnecyZipChange}
              placeholder="Enter general agency zip"
            />
          </div>

          <div className="form-group col-lg-4 col-md-4 col-sm-12 mb-2">
            <label>General Agency Country</label>

            <AtkTextField
              className="input-field"
              type="text"
              id="text"
              value={props?.generalAgencyCountry[props.index]}
              onChange={props.onGeneralAgnecyCountryChange}
              placeholder="Enter general agency country"
            />
          </div>
          <div className="form-group col-lg-4 col-md-4 col-sm-12 pb-2  ">
            <label>Broker Fee</label>
            <div className="input-group ">
              <span className="input-group-text">$</span>
              <AtkTextField
                className="input-field form-control p-0"
                type="text"
                id="text"
                value={props.brokerFee[props.index]}
                onChange={props.onBrokerFeeChange}
                placeholder="Enter broker fee"
              />
            </div>
            <span className="text-danger text-12">{ }</span>
          </div>
          <div
            className={
              props.termError[props.index] != undefined && props.termError[props.index].length > 0 ? "form-group validation-div col-lg-4 col-md-4 col-sm-12 mb-2" : "form-group col-lg-4 col-md-4 col-sm-12 mb-2"
            }
          >
            <label>Term</label>
            <span className="text-danger ">*</span>

            <AtkTextField className="input-field" type="text" id="text" value={props.term[props.index]} onChange={props.onTermChange} placeholder="Enter term" />
            <span className="text-danger text-12">{props.termError[props.index]}</span>
          </div>
        </>
      }
    </div>
  );
}