import React from 'react'
import HistoryIcon from "../../assets/images/History_B.svg";
import { useNavigate } from "react-router-dom";
import NewIcon from "../../assets/images/newquote.png";

export default function COIScanSidebar(props:any) {
    const navigate = useNavigate();

    const onCoiScanClick = () => {
      navigate("/nowcertcoiscan");
    };
    const onCoiScanHistoryClick = () => {
      navigate("/nowcertcoiscanhistory");
    };
  
  return (
    <div className="pt-4 pf-sidebar-block p-0">
    <>
      <span
        className={props.pagename == "coi-scan" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
        onClick={onCoiScanClick}
      >
        <img src={NewIcon} className={props.pagename == "coi-scan" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} />
        <span className="sidebar-menu-title">New COI Scan</span>{" "}
      </span>

      <span
        className={props.pagename == "coi-scan-history" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
        onClick={onCoiScanHistoryClick}
      >
        <img src={HistoryIcon} className={props.pagename == "coi-scan-history" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} />
        <span className="sidebar-menu-title">History</span>{" "}
      </span>
    </>
  </div>
  )
}
