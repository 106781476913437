import React from 'react'
import HistoryIcon from "../../assets/images/History_B.svg";
import { useNavigate } from "react-router-dom";
import NewIcon from "../../assets/images/newquote.png";
export default function ValchoiceSidebar(props:any) {
    const navigate = useNavigate();

    const onValchoiceClick = () => {
      navigate("/valchoice");
    };
    const onValchoiceHistoryClick = () => {
      navigate("/valchoicehistory");
    };
  return (
    <div className="pt-4 pf-sidebar-block p-0">
    <>
      <span
        className={props.pagename == "valchoice" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
        onClick={onValchoiceClick}
      >
        <img src={NewIcon} className={props.pagename == "valchoice" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} />
        <span className="sidebar-menu-title">New Carrier Rate Service Rating</span>{" "}
      </span>

     
      <span
        className={props.pagename == "valchoice-history" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
        onClick={onValchoiceHistoryClick}
      >
        <img
          src={HistoryIcon}
          className={props.pagename == "valchoice-history" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "}
        />
        <span className="sidebar-menu-title">History</span>{" "}
      </span>
     
    </>
  </div>
  )
}
