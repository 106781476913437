import axios from "axios";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CommissionScanLogo from "../../src/assets/images/GenerateCommission_TH.svg";
import PFLogo from ".././assets/images/PF_Logo.svg";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import AddApps from "../OtherPages/AddApps";
import FenrisLogo from "../assets/images/fenris.svg";
import VehicleLogo from "../assets/images/vehicle2_TH.svg";
import ValchoiceLogo from "../assets/images/valchoice.webp";
import Header from "../common/Header";
import CommonValues from "../common/utils";
import ScanIcon from "../assets/images/scan.svg";
import AtkButton from "../AtlaskitControls/AtkButton";
import { Dialog } from "primereact/dialog";
import ReactPlayer from "react-player";
import infoImg2 from "../assets/images/info.svg";
import NaicsLogo from "../assets/images/NAICS_logo.png";
import COILogo from "../assets/images/COI_TH.svg";
import IFSLogo from "../assets/images/IFS ICON_TH.svg";
import RabbitSignLogo from "../assets/images/RabbitSignLogo.png";
import HomeIcon from "../assets/images/HomeOwnerLogo_TH.svg";
import PolicyCompareIcon from "../assets/images/policy-comparision.svg";
import RateLogo from "../assets/images/rate.png";
import MvrLogo from "../assets/images/mvr.png";
import MTMLogo from "../assets/images/MTMLogo.png";
import StarIcon from "@atlaskit/icon/glyph/star";
import StarFilledIcon from "@atlaskit/icon/glyph/star-filled";

export default function LandingPage() {
  const navigate = useNavigate();
  const toast: any = useRef("");
  const [loading, setLoading] = useState(false);
  const [settingLoading, setSettingLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showPrimaryAmsMessage, setShowPrimaryAmsMessage] = useState(false);
  const [showOCR, setShowOCR] = useState(false);
  const appsRef = useRef<OverlayPanel>(null);
  const [nowCertsMessageShow, setNowCertsMessageShow] = useState(false);
  const location = useLocation();
  const hostname = window.location.hostname;
  const [showVideoDialog, setShowVideoDialog] = useState(false);
  const [showInfoDialogOFAcord, setShowInfoDialogOFAcord] = useState(false);
  const [visitedWidgets, setVisitedWidgets] = useState<any>([]);
  const [showInfoDialogOFCOI, setShowInfoDialogOFCOI] = useState(false);
  const [showInfoDialogOFPremfiscan, setShowInfoDialogOFPremfiscan] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [showInfoDialogOfCommisionscan, setShowInfoDialogOfCommisionscan] = useState(false);
  const [showInfoDialogOfPremfi, setShowInfoDialogOfPremfi] = useState(false);
  const [userCount, setUserCount] = useState<any>([]);
  const [userCountLoading, setUserCountLoading] = useState(false);

  useEffect(() => {
    const token = CommonValues.GetToken();

    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    if (localStorage.getItem("PFSettings") == "true") {
      setShowMessage(true);
    } else {
      setShowMessage(false);
    }
    if (localStorage.getItem("tenantId") === "0") {
      window.innerWidth > 767 ? navigate("/commonsettings") : navigate("/commonsettings/primaryamssettings");
    }
    getCommonSettings();
    getUserCount();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  useEffect(() => {
    if (
      showVideoDialog ||
      showInfoDialogOFAcord ||
      showInfoDialogOFCOI ||
      showInfoDialogOFPremfiscan ||
      showInfoDialogOfCommisionscan ||
      showInfoDialogOfPremfi
    ) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => document.body.classList.remove("no-scroll");
  }, [
    showVideoDialog,
    showInfoDialogOFAcord,
    showInfoDialogOFCOI,
    showInfoDialogOFPremfiscan,
    showInfoDialogOfCommisionscan,
    showInfoDialogOfPremfi,
  ]);
  const getCommonSettings = () => {
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/pfcommonsettings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setSettingLoading(true);
    axios(config)
      .then((response: any) => {
        localStorage.setItem("tenantId", response.data.pfTenantId);
        localStorage.setItem("clientCount", response.data.clientCount);
        localStorage.setItem("HawksoftSettings", JSON.stringify(response.data));

        setVisitedWidgets(response.data.visitedWidgets);

        if (response.data.pfTenantId == 0) {
          setSettingLoading(false);
        }
        if (response.data.agencyId != null && response.data.agencyId != "") {
          localStorage.setItem("HawksoftAgencyId", "true");
        } else {
          localStorage.setItem("HawksoftAgencyId", "false");
          if (response.data.pfTenantId == process.env.REACT_APP_Hawksoft_Tenant_Id) {
            window.innerWidth > 767 ? navigate("/commonsettings") : navigate("/commonsettings/primaryamssettings");
          }
        }
        if (response.data.pfList == 0) {
          localStorage.setItem("PFSettings", "true");
          setShowMessage(true);
        } else {
          localStorage.setItem("PFSettings", "false");
          setShowMessage(false);
        }

        if (response.data.pfTenantId == process.env.REACT_APP_NowCerts_Tenant_Id) {
          localStorage.setItem("SelectedAMS", "Nowcert");
          setShowPrimaryAmsMessage(false);
        } else if (response.data.pfTenantId == process.env.REACT_APP_Hawksoft_Tenant_Id) {
          localStorage.setItem("SelectedAMS", "Hawksoft");
          if (hostname == process.env.REACT_APP_Momentum_Url1 || hostname == process.env.REACT_APP_Momentum_Url2) {
            setShowPrimaryAmsMessage(true);
          } else {
            setShowPrimaryAmsMessage(false);
          }
          setSettingLoading(false);
        } else if (response.data.pfTenantId == process.env.REACT_APP_Vertafore_Tenant_Id) {
          localStorage.setItem("SelectedAMS", "Vertafore");
          if (hostname == process.env.REACT_APP_Momentum_Url1 || hostname == process.env.REACT_APP_Momentum_Url2) {
            setShowPrimaryAmsMessage(true);
          } else {
            setShowPrimaryAmsMessage(false);
          }
          getVertaforeSettings();
        }
        if (response.data.pfTenantId == process.env.REACT_APP_NowCerts_Tenant_Id) {
          getNowCertsCreds();
        } else {
        }
      })
      .catch((error: any) => {
        setSettingLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the Setting details";
            toast.current.show({
              severity: "error",
              detail: { errorMessage },
              life: 3000,
            });
          }
        } else {
          errorMessage = "Unknown error while loading the Setting details";
          toast.current.show({
            severity: "error",
            detail: { errorMessage },
            life: 3000,
          });
        }
      });
  };
  const getNowCertsCreds = () => {
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/getcredentials`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setSettingLoading(false);
        if (response.data.ncUserName == null || response.data.ncUserName == null) {
          localStorage.setItem("NowCertsSettings", "false");
          setNowCertsMessageShow(true);
          window.innerWidth > 767 ? navigate("/commonsettings") : navigate("/commonsettings/primaryamssettings");
          setShowOCR(false);
        } else {
          localStorage.setItem("NowCertsSettings", "true");
          setNowCertsMessageShow(false);
          setShowOCR(true);
        }
      })
      .catch((error) => {
        setSettingLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the NowCerts Setting details";
            toast.current.show({
              severity: "error",
              detail: { errorMessage },
              life: 3000,
            });
          }
        } else {
          errorMessage = "Unknown error while loading the NowCerts Setting details";
          toast.current.show({
            severity: "error",
            detail: { errorMessage },
            life: 3000,
          });
        }
      });
  };
  const getVertaforeSettings = () => {
    const token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/vertaforesettings`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        if (response.data.agencyName == "" || response.data.agencyName == null || response.data.userName == "" || response.data.userName == null) {
          localStorage.setItem("VertaforeSetting", "true");
          window.innerWidth > 767 ? navigate("/commonsettings") : navigate("/commonsettings/primaryamssettings");
        } else {
          localStorage.setItem("VertaforeSetting", "false");
        }
        setSettingLoading(false);
      })
      .catch((error: any) => {
        setSettingLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the vertafore settings";
            toast.current.show({
              severity: "error",
              detail: { errorMessage },
              life: 3000,
            });
          }
        }
      });
  };
  const getUserCount = () => {
    setUserCountLoading(true);
    var token = CommonValues.GetToken();
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/usercount`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then((response: any) => {
        setUserCount(response.data);
        setUserCountLoading(false);
      })
      .catch((error: any) => {
        let errorMessage = "";
        setUserCountLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the user count";
            toast.current.show({
              severity: "error",
              detail: { errorMessage },
              life: 3000,
            });
          }
        } else {
          errorMessage = "Unknown error while loading the user count";
          toast.current.show({
            severity: "error",
            detail: { errorMessage },
            life: 3000,
          });
        }
      });
  };
  const onNewQuoteClick = () => {
    isVisitedWidget("PremFi");
    navigate("/premiumfinance");
  };
  const onCoiScanClick = () => {
    isVisitedWidget("COIScan");
    navigate("/nowcertcoiscan");
  };
  const onCommissionScanClick = () => {
    isVisitedWidget("CommissionScan");
    navigate("/commissionscan");
  };
  const onSmartScanClick = () => {
    isVisitedWidget("InsuranceFormScan");
    navigate("/smartscan");
  };
  const onFenrisTileClick = () => {
    isVisitedWidget("AMSPreFill");
    navigate("/fenrisprefill");
  };
  const onSettingTileClick = () => {
    navigate("/pfsettings");
  };
  const gotopfsettingPage = () => {
    {
      window.innerWidth > 767 ? navigate("/commonsettings") : navigate("/commonsettings/primaryamssettings");
    }
  };
  const onVehiclesClick = () => {
    isVisitedWidget("VehiclePreFill");
    navigate("/vehicleprefill");
  };
  const onPFSmartScanClick = () => {
    isVisitedWidget("PremFiScan");
    navigate("/pfscan");
  };
  const onAccordInfoClick = () => {
    setShowInfoDialogOFAcord(true);
  };
  const onCOIInfoClick = () => {
    setShowInfoDialogOFCOI(true);
  };
  const onPremfiScanInfoClick = () => {
    setShowInfoDialogOFPremfiscan(true);
  };
  const onCommisionScanInfoClick = () => {
    setShowInfoDialogOfCommisionscan(true);
  };
  const onPremfiInfoClick = () => {
    setShowInfoDialogOfPremfi(true);
  };
  const onVideoClick = () => {
    setShowVideoDialog(true);
  };
  const onCloseVideoDialogClick = () => {
    setShowVideoDialog(false);
  };
  const onCloseInfoDialogClick = () => {
    setShowInfoDialogOFAcord(false);
    setShowInfoDialogOFCOI(false);
    setShowInfoDialogOFPremfiscan(false);
    setShowInfoDialogOfCommisionscan(false);
    setShowInfoDialogOfPremfi(false);
  };
  const onNaicsClassificationClick = () => {
    isVisitedWidget("NAICSClassification");
    navigate("/naicscode");
  };
  const onValchoiceClick = () => {
    isVisitedWidget("Valchoice");
    navigate("/valchoice");
  };
  const onPolicyComparisonClick = () => {
    isVisitedWidget("PolicyComparision");
    navigate("/policycomparison/compare");
    localStorage.setItem("PolicyPageStatus", "compare");
  };
  const onPolicyAnalyzerClick = () => {
    isVisitedWidget("PolicyComparision");
    navigate("/policycomparison/summary");
    localStorage.setItem("PolicyPageStatus", "summary");
  };
  const isVisitedWidget = (widget: any) => {
    const token = CommonValues.GetToken();
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/visitedwidget?widget=${widget}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {})
      .catch((error: any) => {
        setSettingLoading(false);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            navigate("/");
            CommonValues.Logout(navigate);
          }
        }
      });
  };
  const onRabbitSignClick = () => {
    isVisitedWidget("RabbitSign");
    navigate("/rabbitsign");
  };
  const cardData2 = [
    {
      id: 1,
      title: "Certificate of Insurance Scan",
      description: "Extracts Certificate of Insurance data and generates a CSV file for easy import of certificate holders.",
      logo: COILogo,
      userCount: userCount.find((uc: any) => uc.api === "NowCerts COI Scans")?.count || 0,
      visitedKey: "COIScan",
      rating: 4,
      onClick: onCoiScanClick,
      onInfoClick: onCOIInfoClick,
      isComingSoon: false,
    },
    {
      id: 2,
      title: "Accord and Dec Pages Scan",
      description: "Extracts data from Acord forms and declaration pages to create insured and prospect information.",
      logo: IFSLogo,
      userCount: userCount.find((uc: any) => uc.api === "NowCerts Smart Scan")?.count || 0,
      visitedKey: "InsuranceFormScan",
      rating: 4,
      onClick: onSmartScanClick,
      onInfoClick: onAccordInfoClick,
      isComingSoon: false,
    },
    {
      id: 3,
      title: "PremFi (PFA) Scan",
      description: "Creates or updates insured and policy information in NowCerts by extracting data from premium finance agreements",
      logo: ScanIcon,
      userCount: userCount.find((uc: any) => uc.api === "PF Smart Scan")?.count || 0,
      visitedKey: "PremFiScan",
      rating: 3,
      onClick: onPFSmartScanClick,
      onInfoClick: onPremfiScanInfoClick,
      isComingSoon: false,
    },
    {
      id: 4,
      title: "Commission Scan",
      description: "Captures data from PDF commission reports and converts it into CSV files, ready for import into Momentum.",
      logo: CommissionScanLogo,
      userCount: userCount.find((uc: any) => uc.api === "Commission Statement")?.count || 0,
      visitedKey: "CommissionScan",
      rating: 4,
      onClick: onCommissionScanClick,
      onInfoClick: onCommisionScanInfoClick,
      isComingSoon: false,
    },
    {
      id: 5,
      title: "Policy Comparison",
      description: "Compare the policy dec page or quote letter, compare multiple policies along with limits and coverages",
      logo: PolicyCompareIcon,
      userCount: userCount.find((uc: any) => uc.api === "Policy Comparison")?.count || 0,
      visitedKey: "PolicyComparision",
      rating: 3,
      onClick: onPolicyComparisonClick,
      onInfoClick: () => {},
      isComingSoon: false,
    },
    {
      id: 6,
      title: "Policy/Quote Analyzer and Summary",
      description: "Summarize the policy dec pages or quote letter generating concise summaries, extracting key fields into a structured table",
      logo: PolicyCompareIcon,
      userCount: userCount.find((uc: any) => uc.api === "Policy Comparison")?.count || 0,
      visitedKey: "PolicyComparision",
      rating: 3,
      onClick: onPolicyAnalyzerClick,
      onInfoClick: () => {},
      isComingSoon: false,
    },
  ];

  const cardData3 = [
    {
      id: 1,
      title: "Data Enrichment",
      description: "Automate your AMS data entry process",
      logo: FenrisLogo,
      userCount: userCount.find((uc: any) => uc.api === "Fenris Prefill")?.count || 0,
      visitedKey: "AMSPreFill",
      rating: 4,
      onClick: onFenrisTileClick,
      onInfoClick: () => {},
      isComingSoon: false,
    },
    {
      id: 2,
      title: "Classification Codes (NAICS)",
      description: "Append NAICS Codes to your Customer to easily identify the industries of all your clients",
      logo: NaicsLogo,
      userCount: userCount.find((uc: any) => uc.api === "NAICS Classification")?.count || 0,
      visitedKey: "NAICSClassification",
      rating: 3,
      onClick: onNaicsClassificationClick,
      onInfoClick: () => {},
      isComingSoon: false,
    },
    {
      id: 3,
      title: "Home Owners",
      description: "Instant access to the property data you need",
      logo: HomeIcon,
      userCount: 0,
      // visitedKey: "",
      // onClick: (),
      rating: 0,
      onInfoClick: () => {},
      isComingSoon: true,
    },
    {
      id: 4,
      title: "Carrier Rate Service Rating",
      description: "We provide independent, unbiased ratings on insurance companies",
      logo: ValchoiceLogo,
      userCount: userCount.find((uc: any) => uc.api === "Valchoice Classification")?.count || 0,
      visitedKey: "Valchoice",
      rating: 3,
      onClick: onValchoiceClick,
      onInfoClick: () => {},
      isComingSoon: false,
    },
    {
      id: 5,
      title: "Vehicle Prefill",
      description: "Quickly populate vehicle information forms",
      logo: VehicleLogo,
      userCount: userCount.find((uc: any) => uc.api === "Vehicle Prefill")?.count || 0,
      visitedKey: "VehiclePreFill",
      rating: 3,
      onClick: onVehiclesClick,
      onInfoClick: () => {},
      isComingSoon: false,
    },
  ];

  const cardData4 = [
    {
      id: 1,
      title: "E-Signature",
      description: "Quickly send the same document for signature over and over ",
      logo: RabbitSignLogo,
      userCount: userCount.find((uc: any) => uc.api === "Rabbit Sign")?.count || 0,
      visitedKey: "RabbitSign",
      rating: 4,
      onClick: onRabbitSignClick,
      onInfoClick: () => {},
      isComingSoon: false,
    },
    {
      id: 2,
      title: "MTM Scan",
      description: "Extracts data from MTM Binder pages to create insured information, along with their vehicle details in NowCerts.",
      logo: MTMLogo,
      userCount: 0,
      // visitedKey: "MTMScan",
      // onClick: onMtmScanClick,
      rating: 0,
      onInfoClick: () => {},
      isComingSoon: true,
    },
    {
      id: 3,
      title: "MVR",
      description: "Accurate MVR insights based on a 5-year driver violation powered by court and public data",
      logo: MvrLogo,
      userCount: 0,
      // visitedKey: "",
      // onClick: (),
      rating: 0,
      onInfoClick: () => {},
      isComingSoon: true,
    },
    {
      id: 4,
      title: "Rate Risk Appetite",
      description: "Get instant risk appetite insights by simply entering classification code, policy type, and state",
      logo: RateLogo,
      userCount: userCount.find((uc: any) => uc.api === "Appetite")?.count || 0,
      // visitedKey: "RateRiskAppetite",
      // onClick: onAppetiteClick,
      rating: 0,
      onInfoClick: () => {},
      isComingSoon: true,
    },
  ];
  return (
    <div className="landing-main-page">
      <Toast ref={toast} />

      <Header />
      <div className="content-wrapper container-fluid landing-content-wrapper only-landing-wrapper">
        <div className="row  mx-auto p-2 wrapper-below-div d-flex ">
          {settingLoading ? (
            <div className="col-12  d-flex align-items-center justify-content-center align-items-center">
              <AtkLoader />
            </div>
          ) : (
            <>
              <h2 className="font-bold text-light mb-4">Welcome to Momentum ToolBox</h2>
              <p className="text-16 text-light mb-4">Spotlight</p>
              {showMessage && (
                <div className="col-12 my-3 text-start text-16 text-light">
                  <span>
                    Please{" "}
                    <a
                      onClick={onSettingTileClick}
                      style={{
                        color: "#7ba340",
                      }}
                      className="show-cursor"
                    >
                      choose{" "}
                    </a>{" "}
                    Premium Finance partner(s).
                  </span>
                </div>
              )}
              <div
                className={`landing-page-card-col ${
                  showPrimaryAmsMessage ? "col-lg-3 " : "col-lg-2"
                } col-md-6 col-sm-12 mb-2 d-flex justify-content-center ${showMessage ? "flex-column" : ""} `}
              >
                {showPrimaryAmsMessage ? (
                  <div className="col-12 landing-page-blocks pf-landing-block d-flex align-items-center text-center">
                    <span className="text-dark p-2">
                      This version of the website does not support your AMS; please use the <b>Chrome Extension</b>.
                    </span>
                  </div>
                ) : localStorage.getItem("tenantId") === "0" ? (
                  <div className="col-12 landing-page-blocks pf-landing-block d-flex align-items-center justify-content-center">
                    <span className="text-16 text-dark p-2 text-center">
                      Please{" "}
                      <a onClick={gotopfsettingPage} className="no-primary-ams">
                        select
                      </a>{" "}
                      your primary AMS
                    </span>
                  </div>
                ) : nowCertsMessageShow && CommonValues.GetTenantId() === process.env.REACT_APP_NowCerts_Tenant_Id ? (
                  <div className="col-12 landing-page-blocks pf-landing-block d-flex align-items-center justify-content-center text-center">
                    <span className="text-dark">
                      Please{" "}
                      <a onClick={gotopfsettingPage} className="no-primary-ams">
                        provide
                      </a>{" "}
                      your NowCerts credentials to continue.
                    </span>
                  </div>
                ) : CommonValues.GetTenantId() === process.env.REACT_APP_Hawksoft_Tenant_Id &&
                  (localStorage.getItem("HawksoftAgencyId") === "false" ||
                    localStorage.getItem("HawksoftAgencyId") == null ||
                    localStorage.getItem("HawksoftAgencyId") === "") ? (
                  <div className="col-12 landing-page-blocks pf-landing-block d-flex align-items-center  justify-content-center text-center">
                    <span className="text-dark">
                      Please{" "}
                      <a onClick={gotopfsettingPage} className="no-primary-ams">
                        provide{" "}
                      </a>{" "}
                      your HawkSoft agency id to continue.
                    </span>
                  </div>
                ) : CommonValues.GetTenantId() === process.env.REACT_APP_Vertafore_Tenant_Id &&
                  (localStorage.getItem("VertaforeSetting") === "true" ||
                    localStorage.getItem("VertaforeSetting") == null ||
                    localStorage.getItem("VertaforeSetting") === "") ? (
                  <div className="col-12 landing-page-blocks pf-landing-block d-flex align-items-center justify-content-center text-center">
                    <span className="text-dark">
                      Please{" "}
                      <a onClick={gotopfsettingPage} className="no-primary-ams">
                        provide{" "}
                      </a>{" "}
                      your AMS360 credentials to continue.
                    </span>
                  </div>
                ) : (
                  <>
                    <div className="service-card show-cursor" onClick={onNewQuoteClick}>
                      <div className="row d-flex align-items-center">
                        <div className="col-7">
                          <span className="app-star-ratings">
                            <StarFilledIcon label="star5" />
                            <StarFilledIcon label="star5" />
                            <StarFilledIcon label="star5" />
                            <StarFilledIcon label="star5" />
                            <StarFilledIcon label="star5" />
                          </span>
                        </div>
                        <div className="col-5 text-end">
                          <small className="me-1 user-count">
                            <i className="fa fa-user me-1"></i> {userCount.find((uc: any) => uc.api === "Premium Finance Quotes")?.count || 0}
                          </small>
                        </div>
                      </div>
                      <span className=" show-cursor">
                        <div className="card-header">
                          <img src={PFLogo} alt="Service Logo" className="service-logo" />
                          <h2 className="service-title">PremFi</h2>
                        </div>
                        <p className="service-description">
                          Connects with multiple premium finance companies, allowing users to view finance quotes from several providers at once.{" "}
                        </p>
                      </span>
                      <div className="row">
                        <div className="col-6">
                          <div className="card-buttons ">
                            <img
                              src={infoImg2}
                              title="How it works"
                              className="me-2 show-cursor "
                              height={20}
                              onClick={(e) => {
                                e.stopPropagation();
                                onPremfiInfoClick();
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="card-buttons-2 ">
                            <AtkButton label={visitedWidgets.includes("PremFi") ? "Continue" : "Get Started"} className="pf-primary-btn" />
                          </div>{" "}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <OverlayPanel ref={appsRef} className="addapps-panel">
                  <AddApps />
                </OverlayPanel>
              </div>

              <hr className="text-light" />

              {window.innerWidth > 767 ? (
                <>
                  {showOCR ? (
                    <>
                      {(hostname == process.env.REACT_APP_Momentum_Url1 || hostname == process.env.REACT_APP_Momentum_Url2) &&
                      CommonValues.GetTenantId() === process.env.REACT_APP_NowCerts_Tenant_Id ? (
                        <>
                          {/* Scan tools start here  */}
                          <p className="text-16 text-light mb-4">
                            Streamline your insurance processes with our suite of powerful AI tools to scan and extract data from documents.
                          </p>

                          {cardData2.map((card) => (
                            <div key={card.id} className="landing-page-card-col col-lg-2 col-md-6 col-sm-12 mb-2 d-flex justify-content-center ps-3">
                              <div
                                className={`service-card ${card.isComingSoon ? "" : "show-cursor"}`}
                                onClick={!card.isComingSoon ? card.onClick : undefined}
                              >
                                <div className="row d-flex align-items-center">
                                  <div className="col-7">
                                    {card.rating == 0 ? null : (
                                      <span className="app-star-ratings">
                                        {[...Array(card.rating)].map((_, i) => (
                                          <StarFilledIcon key={i} label={`star-${i}`} />
                                        ))}
                                        {[...Array(5 - card.rating)].map((_, i) => (
                                          <StarIcon key={`empty-${i}`} label={`star-empty-${i}`} />
                                        ))}
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-5 text-end">
                                    {card.isComingSoon ? null : (
                                      <>
                                        {userCountLoading ? (
                                          <AtkLoader />
                                        ) : (
                                          <small className="me-1 user-count">
                                            <i className="fa fa-user me-1"></i>
                                            {card.userCount}
                                          </small>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                                <span className={`${card.isComingSoon ? "" : "show-cursor"}`}>
                                  <div className="card-header">
                                    <img src={card.logo} alt="Service Logo" className="service-logo" />
                                    <h2 className="service-title">{card.title}</h2>
                                  </div>
                                  <p className="service-description">{card.description}</p>
                                </span>
                                <div className="row">
                                  {card.onInfoClick && card.onInfoClick.toString() !== "() => {}" ? (
                                    <div className="col-6">
                                      <div className="col-6">
                                        <div className="card-buttons">
                                          <img
                                            src={infoImg2}
                                            title="How it works"
                                            className="me-2 show-cursor"
                                            height={20}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              card.onInfoClick();
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  <div className="col-6">
                                    <div className="card-buttons-2">
                                      {card.isComingSoon ? (
                                        <div className="coming-soon-banner">
                                          <p>Coming Soon</p>
                                        </div>
                                      ) : (
                                        <AtkButton
                                          label={visitedWidgets.includes(card.visitedKey) ? "Continue" : "Get Started"}
                                          className="pf-primary-btn"
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                          {/* Scan tools ends here  */}

                          <hr className="text-light" />
                          <p className="text-16 text-light mb-4">Third Party Data Enrichment Integrations</p>

                          {/* Data Enrichment Integrations start here  */}

                          {cardData3.map((card) => (
                            <div key={card.id} className="landing-page-card-col col-lg-2 col-md-6 col-sm-12 mb-2 d-flex justify-content-center ps-3">
                              <div
                                className={`service-card ${card.isComingSoon ? "" : "show-cursor"}`}
                                onClick={!card.isComingSoon ? card.onClick : undefined}
                              >
                                <div className="row d-flex align-items-center">
                                  <div className="col-7">
                                    {card.rating == 0 ? null : (
                                      <span className="app-star-ratings">
                                        {[...Array(card.rating)].map((_, i) => (
                                          <StarFilledIcon key={i} label={`star-${i}`} />
                                        ))}
                                        {[...Array(5 - card.rating)].map((_, i) => (
                                          <StarIcon key={`empty-${i}`} label={`star-empty-${i}`} />
                                        ))}
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-5 text-end">
                                    {card.isComingSoon ? null : (
                                      <>
                                        {userCountLoading ? (
                                          <AtkLoader />
                                        ) : (
                                          <small className="me-1 user-count">
                                            <i className="fa fa-user me-1"></i>
                                            {card.userCount}
                                          </small>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                                <span className={` ${card.isComingSoon ? "" : "show-cursor"}`}>
                                  <div className="card-header">
                                    <img src={card.logo} alt="Service Logo" className="service-logo" />
                                    <h2 className="service-title">{card.title}</h2>
                                  </div>
                                  <p className="service-description">{card.description}</p>
                                </span>
                                <div className="row">
                                  {card.onInfoClick && card.onInfoClick.toString() !== "() => {}" ? (
                                    <div className="col-6">
                                      <div className="col-6">
                                        <div className="card-buttons">
                                          <img
                                            src={infoImg2}
                                            title="How it works"
                                            className="me-2 show-cursor"
                                            height={20}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              card.onInfoClick();
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  <div className="col-6">
                                    <div className="card-buttons-2">
                                      {card.isComingSoon ? (
                                        <div className="coming-soon-banner">
                                          <p>Coming Soon</p>
                                        </div>
                                      ) : (
                                        <AtkButton
                                          label={visitedWidgets.includes(card.visitedKey) ? "Continue" : "Get Started"}
                                          className="pf-primary-btn"
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}

                          {/* Data Enrichment Integrations ends here  */}

                          <hr className="text-light" />
                          <p className="text-16 text-light mb-4">Other features</p>

                          {/* Upcoming tools start here  */}
                          {cardData4.map((card) => (
                            <div key={card.id} className="landing-page-card-col col-lg-2 col-md-6 col-sm-12 mb-2 d-flex justify-content-center ps-3">
                              <div
                                className={`service-card ${card.isComingSoon ? "" : "show-cursor"}`}
                                onClick={!card.isComingSoon ? card.onClick : undefined}
                              >
                                <div className="row d-flex align-items-center">
                                  <div className="col-7">
                                    {card.rating == 0 ? null : (
                                      <span className="app-star-ratings">
                                        {[...Array(card.rating)].map((_, i) => (
                                          <StarFilledIcon key={i} label={`star-${i}`} />
                                        ))}
                                        {[...Array(5 - card.rating)].map((_, i) => (
                                          <StarIcon key={`empty-${i}`} label={`star-empty-${i}`} />
                                        ))}
                                      </span>
                                    )}
                                  </div>
                                  <div className="col-5 text-end">
                                    {card.isComingSoon ? null : (
                                      <>
                                        {userCountLoading ? (
                                          <AtkLoader />
                                        ) : (
                                          <small className="me-1 user-count">
                                            <i className="fa fa-user me-1"></i>
                                            {card.userCount}
                                          </small>
                                        )}
                                      </>
                                    )}
                                  </div>
                                </div>
                                <span className={` ${card.isComingSoon ? "" : "show-cursor"}`}>
                                  <div className="card-header">
                                    <img src={card.logo} alt="Service Logo" className="service-logo" />
                                    <h2 className="service-title">{card.title}</h2>
                                  </div>
                                  <p className="service-description">{card.description}</p>
                                </span>
                                <div className="row">
                                  {card.onInfoClick && card.onInfoClick.toString() !== "() => {}" ? (
                                    <div className="col-6">
                                      <div className="col-6">
                                        <div className="card-buttons">
                                          <img
                                            src={infoImg2}
                                            title="How it works"
                                            className="me-2 show-cursor"
                                            height={20}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              card.onInfoClick();
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  <div className="col-6">
                                    <div className="card-buttons-2">
                                      {card.isComingSoon ? (
                                        <div className="coming-soon-banner">
                                          <p>Coming Soon</p>
                                        </div>
                                      ) : (
                                        <AtkButton
                                          label={visitedWidgets.includes(card.visitedKey) ? "Continue" : "Get Started"}
                                          className="pf-primary-btn"
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      ) : null}
                    </>
                  ) : null}
                </>
              ) : null}
            </>
          )}
        </div>
      </div>

      <Dialog visible={showInfoDialogOFAcord} style={{ width: "50vw" }} onHide={onCloseInfoDialogClick} draggable={false} className="infoDialog">
        <div className="modal-content">
          <div className="modal-body p-0">
            <div className="row">
              <div className="col-12">
                <div className="">
                  <h2 className="font-weight-600">
                    {" "}
                    <img src={IFSLogo} className="me-3" width={40} />
                    Accord and Dec pages Scan
                  </h2>
                </div>
                <ReactPlayer className="react-video-dialog my-3" width="100%" height="350px" url={"https://youtu.be/Sv1ZDzkEabU"} controls={true} />
                <p className="text-14">
                  Extracts data from Acord forms and declaration pages to create insured and prospect information, along with their quote and policy
                  details in NowCerts. This AI-powered tool efficiently processes Acord 125, 126, and 130 forms, as well as declaration pages and
                  quote sheets, streamlining the data extraction process. By automating the creation of accurate insured and prospect records, this
                  tool enhances productivity and reduces manual entry, making it easier for users to manage their insurance information in NowCerts.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog visible={showInfoDialogOFCOI} style={{ width: "50vw" }} onHide={onCloseInfoDialogClick} draggable={false} className="infoDialog">
        <div className="modal-content">
          <div className="modal-body p-0">
            <div className="row">
              <div className="col-12">
                <div className="">
                  <h2 className="font-weight-600">
                    {" "}
                    <img src={COILogo} className="me-3" width={40} />
                    Certificate of Insurance Scan
                  </h2>
                </div>
                <ReactPlayer className="react-video-dialog my-3" width="100%" height="350px" url={"https://youtu.be/satgAN6DGac"} controls={true} />
                <p className="text-14">
                  Extracts Certificate of Insurance data and generates a CSV file for easy import of certificate holders. Currently compatible with
                  Acord 25 Certificates, this AI-powered tool simplifies the extraction process by organizing certificate holder information into
                  clear columns, making it easy to import into the NowCerts Data Import tool.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog visible={showInfoDialogOFPremfiscan} style={{ width: "50vw" }} onHide={onCloseInfoDialogClick} draggable={false} className="infoDialog">
        <div className="modal-content">
          <div className="modal-body p-0">
            <div className="row">
              <div className="col-12">
                <div className="">
                  <h2 className="font-weight-600">
                    {" "}
                    <img src={ScanIcon} className="me-3" width={40} />
                    PremFi (PFA) Scan
                  </h2>
                </div>
                <p className="text-14">
                  Extracts data from premium finance agreements to update or create insured and policy information in NowCerts. This AI-powered tool
                  currently processes agreements from Capital Premium Finance and IPFS, streamlining the data management process and enhancing
                  accuracy. By automating the extraction of key information, it simplifies the creation and updating of insured records, making it
                  easier for users to maintain comprehensive policy details in NowCerts. With many more premium finance agreements to be added in the
                  future.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Dialog
        visible={showInfoDialogOfCommisionscan}
        style={{ width: "50vw" }}
        onHide={onCloseInfoDialogClick}
        draggable={false}
        className="infoDialog"
      >
        <div className="modal-content">
          <div className="modal-body p-0">
            <div className="row">
              <div className="col-12">
                <div className="">
                  <h3 className="font-weight-600">
                    {" "}
                    <img src={CommissionScanLogo} className="me-3" width={40} />
                    Commission Scan
                  </h3>
                </div>
                <p className="text-14">
                  Captures data from PDF commission reports and converts it into CSV files, ready for import into Momentum. Efficiently processing PDF
                  files streamlines the data extraction process to provide accurate and structured commission data in CSV format.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog visible={showInfoDialogOfPremfi} style={{ width: "50vw" }} onHide={onCloseInfoDialogClick} draggable={false} className="infoDialog">
        <div className="modal-content">
          <div className="modal-body p-0">
            <div className="row">
              <div className="col-12">
                <div className="">
                  <h3 className="font-weight-600">
                    {" "}
                    <img src={PFLogo} className="me-3" width={40} />
                    PremFi
                  </h3>
                </div>
                <p className="text-14">
                  Connects with multiple premium finance companies, allowing users to view finance quotes from several providers at once. Once a quote
                  is accepted, it enables instant, one-click generation of a finance agreement. By automating access to multiple financing options and
                  simplifying agreement generation, this tool enhances productivity and makes premium financing more efficient.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
