import React, { FC, useEffect, useState, useRef } from "react";
import axios from "axios";
import CommonValues, { Logout } from "../../../common/utils";
import { Toast } from "primereact/toast";
import AtkTextField from "../../../AtlaskitControls/AtkTextField";
import AtkButton from "../../../AtlaskitControls/AtkButton";
import AtkLoader from "../../../AtlaskitControls/AtkLoader";
import AtkMessage from "../../../AtlaskitControls/AtkMessage";
import { Navigate, useNavigate } from "react-router-dom";
import WatchFilledIcon from "@atlaskit/icon/glyph/watch-filled";
import WatchIcon from "@atlaskit/icon/glyph/watch";

export default function FirstInsuranceSettings() {
  const [loading, setLoading] = useState(false);
  const [firstinsuranceLoading, setFirstInsuranceLoading] = useState(false);
  const [deleteFirstInsuranceLoading, setDeleteFirstInsuranceLoading] = useState(false);
  const [errorMessageUsername, setErrorMessageUsername] = useState("");
  const [errorMessagePassword, setErrorMessagePassword] = useState("");
  const [firstInsuranceUsername, setFirstInsuranceUsername] = useState("");
  const [firstInsurancePassword, setFirstInsurancePassword] = useState("");
  const [showIGCredsMsg, setShowIGCredsMsg] = useState(false);
  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState("password");
  const toast: any = useRef("");

  useEffect(() => {
    let token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    getFirstInsuranceCreds();
  }, []);
  const getFirstInsuranceCreds = () => {
    setLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/firstinsurancecreds`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        if (response.status == 204) {
          setFirstInsuranceUsername("");
          setFirstInsurancePassword("");
          setShowIGCredsMsg(true);
        } else {
          setShowIGCredsMsg(false);
          setFirstInsuranceUsername(response.data.username);
          if (response.data.username !== null && response.data.username !== "" && response.data.password === "") {
            setFirstInsurancePassword("*********");
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the First Insurance Setting details";
          }
        } else {
          errorMessage = "Unknown error while loading the First Insurance Setting details";
        }
        toast.current.show({ severity: "error", detail: { errorMessage } });
        setLoading(false);
      });
  };
  const firstInsuranceCheckValidation = () => {
    let formIsValid = true;
    setErrorMessageUsername("");
    setErrorMessagePassword("");

    if (firstInsuranceUsername == "" || firstInsuranceUsername == null) {
      formIsValid = false;
      setErrorMessageUsername("Please enter login");
    }
    if (firstInsurancePassword == "" || firstInsurancePassword == null) {
      formIsValid = false;
      setErrorMessagePassword("Please enter password");
    }

    return formIsValid;
  };
  const onUpdateFirstInsuranceClick = () => {
    if (firstInsuranceCheckValidation()) {
      setFirstInsuranceLoading(true);
      let token = CommonValues.GetToken();
      var data = JSON.stringify({
        Username: firstInsuranceUsername,
        Password: firstInsurancePassword,
      });

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_UserService_Url}/api/user/firstinsurancecreds`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((response: any) => {
          setFirstInsuranceLoading(false);
          toast.current.show({ severity: "success", detail: "First Insurance Settings updated successfully" });
          getFirstInsuranceCreds();
        })
        .catch((error: any) => {
          console.log(error);
          let errorMessage = "";
          if (error.response != null) {
            if (error.response.status == 401) {
              CommonValues.Logout(navigate);
            } else if (error.response.status == 409) {
              errorMessage = "It look likes you are not registered with First Insurance, Please register first.";
            } else {
              errorMessage = "Unknown error while updating the First Insurance Settings";
            }
          } else {
            errorMessage = "Unknown error while updating the First Insurance Settings";
          }
          toast.current.show({ severity: "error", detail: { errorMessage } });
          setFirstInsuranceLoading(false);
        });
    }
  };
  const onDeleteFirstInsuranceClick = () => {
    setErrorMessageUsername("");
    setErrorMessagePassword("");
    setDeleteFirstInsuranceLoading(true);
    let token = CommonValues.GetToken();
    var config = {
      method: "delete",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/firstinsurancecreds`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setDeleteFirstInsuranceLoading(false);
        toast.current.show({ severity: "success", detail: "First Insurance Settings deleted successfully" });
        getFirstInsuranceCreds();
      })
      .catch((error: any) => {
        console.log(error);
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while deleting the First Insurance Settings";
          }
        } else {
          errorMessage = "Unknown error while deleting the First Insurance Settings";
        }
        toast.current.show({ severity: "error", detail: { errorMessage } });

        setDeleteFirstInsuranceLoading(false);
      });
  };
  const onKeyUpdateFirstInsuranceClick = (e: any) => {
    if (e.keyCode == 13) {
      onUpdateFirstInsuranceClick();
    }
  };
  const onUsernameChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setFirstInsuranceUsername(e.target.value);
  };
  const onPasswordChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setFirstInsurancePassword(e.target.value);
  };
  const onShowPassword = () => {
    if (passwordType == "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  return (
    <>
      <div className="row mx-auto">
        <Toast ref={toast} />
        <div className="col-12">
          <div className="pf-setting-col">
            <h4 className="pf-setting-title">First Insurance Premium Financing</h4>

            <div className={loading ? "row pf-setting-inputs  mx-auto div-blur-loading d-flex align-items-center" : "row pf-setting-inputs  mx-auto d-flex align-items-center"}>
              <div className="col-4 d-flex align-items-center mt-2">
                <label className="form-label">
                  Login
                  <span className="text-danger">*</span>
                </label>
              </div>
              <div className={errorMessageUsername.length > 0 ? "col-8 mt-2 validation-div" : "col-8 mt-2 "}>
                <AtkTextField
                  type="text"
                  id="username"
                  name="firstInsuranceUsername"
                  className="input-field"
                  onChange={onUsernameChange}
                  value={firstInsuranceUsername}
                  onKeyDown={onKeyUpdateFirstInsuranceClick}
                  placeholder="Enter login"
                />
                <span className="text-danger text-12">{errorMessageUsername}</span>
              </div>

              <div className="col-4 d-flex align-items-center mt-2  mb-2">
                <label className="form-label">
                  Password
                  <span className="text-danger">*</span>
                </label>
              </div>
              <div
                className={errorMessagePassword.length > 0 ? "col-8 mt-2 validation-div   position-relative" : "col-8 mt-2  mb-2 position-relative"}
              >
                <AtkTextField
                  type={passwordType}
                  className="input-field"
                  id="headerKey"
                  name="firstInsurancePassword"
                  onChange={onPasswordChange}
                  value={firstInsurancePassword}
                  onKeyDown={onKeyUpdateFirstInsuranceClick}
                  autoComplete="new-password"
                  placeholder="Enter password"
                />
                <span className="eye-icon" onClick={onShowPassword}>
                  {passwordType === "password" ? <WatchFilledIcon label="Pause" /> : <WatchIcon label="Play" />}
                </span>
              </div>
              {errorMessagePassword.length > 0 ? (
                <>
                  <div className="col-4  mb-2"></div>
                  <div className=" col-8  mb-2">
                    <span className="text-danger text-12">{errorMessagePassword}</span>
                  </div>
                </>
              ) : null}
              <div className="col-md-12 mb-2">
                {showIGCredsMsg ? <AtkMessage appearance="information" messageText="Using InsuranceGIG Credentials" /> : null}
              </div>
              <div className="row mx-auto  setting-btn-position ">
                <div className="col-4 mt-3 mb-1"></div>
                <div className="col-4 mt-3 mb-1">
                  {deleteFirstInsuranceLoading ? (
                    <AtkLoader></AtkLoader>
                  ) : (
                    <AtkButton label="Reset" onClick={onDeleteFirstInsuranceClick} className="pf-primary-btn w-100" />
                  )}
                </div>
                <div className="col-4 mt-3 mb-1">
                  {firstinsuranceLoading ? (
                    <AtkLoader></AtkLoader>
                  ) : (
                    <AtkButton label="Save" onClick={onUpdateFirstInsuranceClick} className="pf-primary-btn w-100" />
                  )}{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
