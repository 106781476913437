import React from "react";
import HistoryIcon from "../assets/images/History_B.svg";
import { useNavigate } from "react-router-dom";
import NewIcon from "../assets/images/NewQuote_B 1.svg";

export default function NaicsSidebar(props: any) {
  const navigate = useNavigate();
  const onGenerateNaicsCodeClick = () => {
    navigate("/naicscode");
  };
  const onNaicsClassificationHistoryClick = () => {
    navigate("/naicshistory");
  };
  return (
    <div className="pt-4 pf-sidebar-block p-0">
      <>
        <span
          className={props.pagename == "naics-landing-page" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
          onClick={onGenerateNaicsCodeClick}
        >
          <img src={NewIcon} className={props.pagename == "naics-landing-page" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} />
          <span className="sidebar-menu-title">Get NAICS Code</span>{" "}
        </span>
        <span
          className={props.pagename == "naics-classification-history" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
          onClick={onNaicsClassificationHistoryClick}
        >
          <img
            src={HistoryIcon}
            className={props.pagename == "naics-classification-history" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "}
          />
          <span className="sidebar-menu-title">History</span>{" "}
        </span>
      </>
    </div>
  );
}
