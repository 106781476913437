import React, { FC, useEffect, useState, useRef } from "react";
import { Toast } from "primereact/toast";
import AtkTextField from "../AtlaskitControls/AtkTextField";
import StarFilledIcon from "@atlaskit/icon/glyph/star-filled";
import StarIcon from "@atlaskit/icon/glyph/star";
import AtkButton from "../AtlaskitControls/AtkButton";
import WatchFilledIcon from "@atlaskit/icon/glyph/watch-filled";
import WatchIcon from "@atlaskit/icon/glyph/watch";
import CommonValues from "../common/utils";
import axios from "axios";
import AtkMessage from "../AtlaskitControls/AtkMessage";
import AtkLoader from "../AtlaskitControls/AtkLoader";
import { Logout } from "../common/utils";
import { Navigate, useNavigate } from "react-router-dom";
const hostname = window.location.hostname;

export default function NowcertsSetting(props: any) {
  const [showNowCertsSettings, setShowNowCertsSettings] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nowCertsLoading, setNowCertsLoading] = useState(false);
  const [errorMessageNcUserName, setErrorMessageNcUserName] = useState("");
  const [errorMessageNcPassword, setErrorMessageNcPassword] = useState("");
  const [ncUserName, setNcUserName] = useState("");
  const [ncPassword, setNcPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [ncPasswordType, setNcPasswordType] = useState("password");
  const [errorMessageForNCResponse, setErrorMessageForNCResponse] = useState("");
  const [successMessageForNCResponse, setSuccessMessageForNCResponse] = useState("");
  const navigate = useNavigate();
  const toast: any = useRef("");

  useEffect(() => {
    setShowNowCertsSettings(true);
    let token = CommonValues.GetToken();
    if (token == "" || token == null) {
      CommonValues.Logout(navigate);
    }
    setTimeout(() => {
      getNowCertsCreds(false);
    }, 10);
  }, []);
  const momentumcheckValidation = () => {
    let formIsValid = true;
    setErrorMessageNcUserName("");
    if (ncUserName == "" || ncUserName == null) {
      formIsValid = false;
      setErrorMessageNcUserName("Please enter username");
    }
    return formIsValid;
  };
  const nowCertscheckValidation = () => {
    let formIsValid = true;
    setErrorMessageNcUserName("");
    setErrorMessageNcPassword("");
    if (ncUserName == "" || ncUserName == null) {
      formIsValid = false;
      setErrorMessageNcUserName("Please enter username");
    }
    if (ncPassword == "" || ncPassword == null) {
      formIsValid = false;
      setErrorMessageNcPassword("Please enter password");
    }
    return formIsValid;
  };
  const onUpdateMomentumClick = () => {
    if (momentumcheckValidation()) {
      setNowCertsLoading(true);
      let token = CommonValues.GetToken();
      var data = JSON.stringify({
        NCUserName: ncUserName,
      });
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_UserService_Url}/api/user/verifyMomentumCreds`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((response: any) => {
          if (response.data != "" && response.data != null) {
            localStorage.setItem("NCToken", response.data.access_token);
            localStorage.setItem("NCAgencyId", response.data.agencyId);
          }
          getNowCertsAgentDetails();

          setNowCertsLoading(false);
          toast.current.show({
            severity: "success",
            detail: "NowCerts Settings updated successfully",
            life: 3000,
          });
        })
        .catch((error: any) => {
          console.log(error);
          let errorMessage = "";
          if (error.response != null) {
            if (error.response.status == 401) {
              navigate("/");
              CommonValues.Logout(navigate);
            } else if (error.response.status == 409) {
              toast.current.show({
                severity: "info",
                detail: "It look likes you are not registered with NowCerts, Please register first.",
                life: 5000,
              });
            } else {
              toast.current.show({
                severity: "error",
                detail: "Unknown error while updating the NowCerts Settings",
                life: 5000,
              });
            }
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while updating the NowCerts Settings",
              life: 5000,
            });
          }
          setNowCertsLoading(false);
        });
    }
  };
  const onUpdateNowCertsClick = () => {
    if (nowCertscheckValidation()) {
      setNowCertsLoading(true);
      let token = CommonValues.GetToken();
      var data = JSON.stringify({
        NCUserName: ncUserName,
        NCPassword: ncPassword,
      });

      var config = {
        method: "post",
        url: `${process.env.REACT_APP_UserService_Url}/api/user/addNowCertcreds`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then((response: any) => {
          if (response.data != "" && response.data != null) {
            localStorage.setItem("NCToken", response.data.access_token);
            localStorage.setItem("NCAgencyId", response.data.agencyId);
          }
          setNowCertsLoading(false);
          toast.current.show({ severity: "success",  detail: "NowCerts Settings updated successfully", life: 3000 });
          setTimeout(() => getNowCertsCreds(false), 400);
          getNowCertsAgentDetails();
        })
        .catch((error: any) => {
          console.log(error);
          if (error.response != null) {
            if (error.response.status == 401) {
              CommonValues.Logout(navigate);
            } else if (error.response.status == 409) {
              toast.current.show({
                severity: "info",
                detail: "It look likes you are not registered with NowCerts, Please register first.",
                life: 5000,
              });
            } else {
              toast.current.show({
                severity: "error",
                detail: "Unknown error while updating the NowCerts Settings",
                life: 5000,
              });
            }
          } else {
            toast.current.show({
              severity: "error",
              detail: "Unknown error while updating the NowCerts Settings",
              life: 5000,
            });
          }
          setNowCertsLoading(false);
        });
    }
  };
  const getNowCertsAgentDetails = () => {
    setLoading(true);
    var token = CommonValues.GetToken();
    let nowCertsToken = localStorage.getItem("NCToken") ? localStorage.getItem("NCToken") : "";
    let nowCertsAgencyId = localStorage.getItem("NCAgencyId") ? localStorage.getItem("NCAgencyId") : "";
    var config = {
      method: "get",
      url: `${process.env.REACT_APP_NOWCERTSAPI}/api/nowcert/agencyinfo?nowCertsToken=${nowCertsToken}&nowCertsAgencyId=${nowCertsAgencyId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(config)
      .then((response: any) => {
        let agencyData = {
          agencyName: response.data.agencyName,
          agencyAddress1: response.data.addressLine1,
          agencyAddress2: response.data.addressLine2,
          agencyCity: response.data.city,
          agencyState: response.data.state,
          agencyZip: response.data.zipCode,
          agencyCountry: "USA",
          agencyPhone:response.data.phone
        };
        onSaveAmsAgencySetting(agencyData);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.response != null) {
          if (error.response.status == 401) {
            CommonValues.Logout(navigate);
          } else if (error.response.status == 409) {
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while getting Nowcerts agent details", life: 3000 });
          }
        } else {
          toast.current.show({ severity: "error", detail: "Unknown error while getting Nowcerts agent details", life: 3000 });
        }
      });
  };
  const onSaveAmsAgencySetting = (agencyData: any) => {
    const token = CommonValues.GetToken();
    var data = {
      CompanyId: agencyData.CompanyId,
      AgencyName: agencyData.agencyName,
      Country: agencyData.agencyCountry,
      Address1: agencyData.agencyAddress1,
      Address2: agencyData.agencyAddress2,
      State: agencyData.agencyState,
      Zip: agencyData.agencyZip,
      City: agencyData.agencyCity,
      Phone:agencyData.agencyPhone
    };
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/amsagencysettings`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "Application/json",
      },
      data: data,
    };

    axios(config)
      .then((response: any) => {
        props.onPrimaryAMSSelected();
        props.getCommonSettings();
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else if (error.response.status === 404) {
            toast.current.show({ severity: "error", detail: "Invalid ams360 credentials!", life: 3000 });
          } else {
            toast.current.show({ severity: "error", detail: "Unknown error while saving the AMS360 settings", life: 3000 });
          }
        }

      });
  };
  const onKeyUpdateNowCertsClick = (e: any) => {
    if (e.keyCode == 13) {
      if (hostname === process.env.REACT_APP_Momentum_Url1 || hostname === process.env.REACT_APP_Momentum_Url2) {
        onUpdateMomentumClick();
      } else {
        onUpdateNowCertsClick();
      }
    }
  };
  const getNowCertsCreds = (val:any) => {
    setNcUserName("");
    setNcPassword("");
    setErrorMessageNcPassword("");
    setErrorMessageNcUserName("");
    setLoading(true);
    const token = CommonValues.GetToken();
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_UserService_Url}/api/user/getcredentials`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios(config)
      .then((response: any) => {
        setLoading(false);
        setErrorMessage("");
        if (val) {
          if (response.data.ncUserName!= null && response.data.ncUserName !== "") {
            getNowCertsAgentDetails();
          } else {
            toast.current.show({ severity: "info", detail: "Please enter nowcerts AMS credentials", life: 3000 });
          }
        }
        if (response.data.ncUserName != null && response.data.ncUserName !== "") {
          setNcUserName(response.data.ncUserName);
        }
        if (response.data.ncUserName != null && response.data.ncUserName !== "" && response.data.ncPassword == null) {
          setNcPassword("*********");
        }

      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response != null) {
          if (error.response.status === 401) {
            CommonValues.Logout(navigate);
          } else {
            errorMessage = "Unknown error while loading the NowCerts Setting details";
            toast.current.show({ severity: "error",  detail:{errorMessage}, life: 3000 });

          }
        } else {
          errorMessage = "Unknown error while loading the NowCerts Setting details";
          toast.current.show({ severity: "error",  detail:{errorMessage}, life: 3000 });
        }
        setLoading(false);
      });
  };
  const onNCUserNameChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setNcUserName(e.target.value);
  };
  const onNCPasswordChange = (e: any) => {
    if (e.target.value.length > 255) {
      return;
    }
    setNcPassword(e.target.value);
  };
  const onShowPassword = () => {
    if (ncPasswordType === "password") {
      setNcPasswordType("text");
      return;
    }
    setNcPasswordType("password");
  };
  const onNowcertsSelect =()=>{
    getNowCertsCreds(true)
  }
  return (
    <>
      <Toast ref={toast} />

      <div className="common-setting-block height-small">
        <div className="row ams-header mx-auto">
          <div className="col-6">
            <h4 className="text-20 fw-normal">NowCerts</h4>
          </div>
          <div className="col-6 text-end">
            <span className="ams-notselected-icon">
              {props.nowcertsSelected ? (
                <span
                onClick={() => {
                  onNowcertsSelect();
                }}
                  className="bookmark-history-icon-after-save"
                >
                  <StarFilledIcon label="Bookmarked" />
                </span>
              ) : (
                <span
                onClick={() => {
                  onNowcertsSelect();
                }}
                  className="bookmark-history-icon"
                >
                  <StarIcon label="Bookmark" />
                </span>
              )}
            </span>
          </div>
        </div>
        <div className="row mx-auto text-12 p-1">
          {showNowCertsSettings ? (
            <div className="">
              {loading ? (
                <div className="d-flex justify-content-center align-items-center mt-2">
                  <AtkLoader />
                </div>
              ) : (
                <>
                  <div
                    className={
                      errorMessageNcUserName.length > 0 ? "form-group position-relative validation-div mt-2" : "position-relative form-group mt-2"
                    }
                  >
                    <label className="form-label">
                      NowCerts Username
                     <span className="text-danger">*</span>
                    </label>

                    <AtkTextField
                      type="text"
                      id="ncUserName"
                      name="ncUserName"
                      onChange={onNCUserNameChange}
                      value={ncUserName}
                      onKeyDown={onKeyUpdateNowCertsClick}
                      className="input-field"
                      placeholder="Enter NowCerts username"
                    />
                    <span className="text-danger small">{errorMessageNcUserName}</span>
                  </div>
                  {hostname === process.env.REACT_APP_Momentum_Url1 || hostname === process.env.REACT_APP_Momentum_Url2 ? null : (
                    <>
                      <div
                        className={
                          errorMessageNcPassword.length > 0 ? "form-group position-relative validation-div mt-2" : "position-relative form-group mt-2"
                        }
                      >
                        <label className="form-label">
                          NowCerts Password
                         <span className="text-danger">*</span>
                        </label>
                        <AtkTextField
                          type={ncPasswordType}
                          id="ncPassword"
                          onChange={onNCPasswordChange}
                          value={ncPassword}
                          onKeyDown={onKeyUpdateNowCertsClick}
                          className="input-field"
                          placeholder="Enter NowCerts password"
                          autoComplete="new-password"
                        />
                        <span className="nc-eye-icon" onClick={onShowPassword}>
                          {ncPasswordType === "password" ? <WatchFilledIcon label="Pause" /> : <WatchIcon label="Play" />}
                        </span>{" "}
                      </div>
                    </>
                  )}
                  <div className="col-md-12  ">
                    {errorMessageNcPassword != null && errorMessageNcPassword != "" ? (
                      <span className="text-danger text-12">{errorMessageNcPassword}</span>
                    ) : null}
                  </div>
                  {hostname === process.env.REACT_APP_Momentum_Url1 || hostname === process.env.REACT_APP_Momentum_Url2 ? (
                    <div className="col-12 text-end mt-2">
                      {nowCertsLoading ? (
                        <AtkLoader className="mt-1"></AtkLoader>
                      ) : (
                        <AtkButton label="Save" onClick={onUpdateMomentumClick} className="pf-secondary-btn w-auto " />
                      )}
                    </div>
                  ) : (
                    <div className="col-12 text-end mt-2">
                      {nowCertsLoading ? (
                        <AtkLoader></AtkLoader>
                      ) : (
                        <AtkButton label="Save" onClick={onUpdateNowCertsClick} className="pf-secondary-btn w-auto my-3" />
                      )}
                    </div>
                  )}

                  <div className="col-12  mt-2 mb-2">
                    {errorMessageForNCResponse != null && errorMessageForNCResponse != "" ? (
                      <AtkMessage appearance="error" messageText={errorMessageForNCResponse} />
                    ) : null}
                    {successMessageForNCResponse != null && successMessageForNCResponse != "" ? (
                      <AtkMessage appearance="success" messageText={successMessageForNCResponse} />
                    ) : null}
                  </div>
                </>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
}