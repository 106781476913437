import React from 'react'
import HistoryIcon from "../../assets/images/History_B.svg";
import { useNavigate } from "react-router-dom";
import NewIcon from "../../assets/images/NewQuote_B 1.svg";

export default function PFScanSidebar(props: any) {
    const navigate = useNavigate();
    const onPremfiScanClick = () => {
      navigate("/pfscan");
    };
    const onPremfiScanHistoryClick = () => {
      navigate("/pfscanhistory");
    };
    return (
      <div className="pt-4 pf-sidebar-block p-0">
        <>
          <span
            className={props.pagename == "pfscan-landing-page" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
            onClick={onPremfiScanClick}
          >
            <img src={NewIcon} className={props.pagename == "pfscan-landing-page" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} />
            <span className="sidebar-menu-title">New PremFi scan</span>{" "}
          </span>
          <span
            className={props.pagename == "pfscan-history" ? "pf-sidebar-menu active " : "pf-sidebar-menu pf-hover-effect show-cursor"}
            onClick={onPremfiScanHistoryClick}
          >
            <img src={HistoryIcon} className={props.pagename == "pfscan-history" ? "pf-sidebar-img pf-sidebar-img-active" : "pf-sidebar-img "} />
            <span className="sidebar-menu-title">History</span>{" "}
          </span>
        </>
      </div>
    );
}
